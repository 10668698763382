import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Link } from '../../Atoms'
import { connect } from "react-redux";
import { SocialIcons, Dropdown, SearchBox } from "../index";
import { logoClean, handshake, phone, email, burger, close, signin, chinese, english } from "../../Assets";
import "./PrimaryNavigation.css";
// import { serURL } from "../../API/url";
import { lang } from "moment";

class PrimaryNavigation extends PureComponent {

    constructor(props) {
        super(props);

      this.state = {
        langButton: 'CH', 
        imgSrc: chinese,
        linkTo: '/investor-ch',
        open: null,
        shouldReDirect: false,
        redirectUrl: "",
        website: 'https://investor.ginkgomic.com',
      };
    }

    componentDidMount() {
        this.initializeComponent();
    }

    initializeComponent = () => {
        if(window.location.pathname.includes('/investor-ch')){
            this.setState((prevState) => {
                return {
                    ...prevState,
                    langButton: 'EN',
                    imgSrc: english,
                    linkTo: '/investor'
                };
            });
        }
        else if(window.location.pathname.includes('/investor')){
            this.setState((prevState) => {
                return {
                    ...prevState,
                    langButton: 'CH',
                    imgSrc: chinese,
                    linkTo: '/investor-ch'
                };
            });
        }
    }

    close = function() {
        if (!this.state.open) return;
        this.setState({ open: false });
    }.bind(this);

    updateUserType = function(userType) {
        this.props.setUserType(userType);
        if (userType === "broker") {
            this.setState({
                shouldReDirect: true,
                redirectUrl: "/"
            });
        } else if (userType === "investor") {
            this.setState((prevState) => {
                return {
                        shouldReDirect: true,
                        redirectUrl: prevState.langButton=='CH' ? '/investor' : '/investor-ch',
                };
              });
        }
        
    }.bind(this);


    langSwitch = () => {
         this.setState((prevState) => {
            return {
              langButton: prevState.langButton === 'CH' ? 'EN' : 'CH',
                    imgSrc: prevState.langButton === 'CH' ? english : chinese,
                    linkTo: prevState.langButton === 'CH' ? '/investor' : '/investor-ch'
            };
          });
        } 
    
    render() {
        const isMobile = window.innerWidth < 800;
        const { open, shouldReDirect, redirectUrl } = this.state;
        const { userType, location } = this.props;
        return (
            <div className={`PrimaryNavigation ${isMobile ? "fontSizeM" : "fontSizeXXS"} ${open ? "open" : ""}`}>
                {shouldReDirect ? <Redirect to={redirectUrl} /> : <div></div>}
                {!isMobile || open ? (
                    <div className="topNav primaryBackground pagePadding">
                        <div className="userTypeWrap">
                            <span
                                className={`topNavItem roundedTopSmall ${
                                    userType === "broker" && !location.pathname.includes("investor")
                                        ? "lightBackground"
                                        : "lightBlueBackground"
                                }`}
                                onClick={() => this.updateUserType("broker")}
                            >
                                <rb className="brokerTabWrap">MORTGAGE&nbsp;BROKER</rb>
                            </span>
                            <span
                                className={`topNavItem roundedTopSmall ${
                                    userType === "investor" || location.pathname.includes("investor")
                                        ? "lightBackground"
                                        : "lightBlueBackground"
                                }`}
                                onClick={() => this.updateUserType("investor")}
                            >
                                <rb className="brokerTabWrap">INVESTOR</rb>
                            </span>
                        </div>
                        <div className="row rightSide lightText slideInRight">
                            {userType === "investor" || location.pathname.includes("investor") ? (
                              <div className="signinType">
                                <a href={this.state.website} target="_blank">
                                    <img src={signin} style={{width:'80px',marginTop:'6px'}}/></a>
                              <input 
                                type="radio" 
                                id="websiteRadio1" 
                                name="websiteRadio" 
                                value="https://investor.ginkgomic.com"
                                checked={this.state.website === "https://investor.ginkgomic.com"}
                                onChange={e => this.setState({ website: e.target.value })}
                              />
                              
                              <label className="investor" htmlFor="websiteRadio1">Investor</label>
                              <input 
                                type="radio" 
                                id="websiteRadio2" 
                                name="websiteRadio" 
                                value="https://sf.ginkgomic.com"
                                checked={this.state.website === "https://sf.ginkgomic.com"}
                                onChange={e => this.setState({ website: e.target.value })}
                              />
                              <label className="advisor" htmlFor="websiteRadio2">Advisor</label>
                        </div>
    ) : (
                                <Link onClick={this.close} to="/submitadeal" className="row iconRow">
                                    <div className="iconWrap center">
                                        <img src={handshake} alt="" />
                                    </div>
                                    <span>
                                        <div className="darkText">Submit a Deal</div>
                                    </span>
                                </Link>
                            )}
                            <a href="tel:14169015133" className="row iconRow">
                                <div className="iconWrap center">
                                    <img src={phone} alt="" />
                                </div>

                                <span>
                                    <div className="darkText">(1) 416-901-5133</div>
                                </span>
                            </a>
                            <a
                                href={`mailto:${
                                    userType === "investor" || location.pathname.includes("investor")
                                        ? "investor@ginkgomic.com"
                                        : "underwrite@ginkgomic.com"
                                }`}
                                className="row iconRow"
                            >
                                <div className="iconWrap center">
                                    <img src={email} alt="" />
                                </div>

                                <span>
                                    <div className="darkText">
                                        {userType === "investor" || location.pathname.includes("investor")
                                            ? "investor@ginkgomic.com"
                                            : "underwrite@ginkgomic.com"}
                                    </div>
                                </span>
                            </a>

                            <SocialIcons />
                            {/* <Dropdown /> */}
                        </div>                     
                        {userType === "investor" || location.pathname.includes("investor") ? (
                            <Link onClick={this.langSwitch} to={this.state.linkTo} className="row iconRow">
                                <div className="iconWrap center">
                                <img src={this.state.imgSrc} alt="" />
                                </div>
                            </Link>
                        ) : ""}
                        </div>
                ) : (
                    <span></span>
                )}

                <div className="navigationContent pagePadding">
                    <div className="menuLogoRowWrap slideInLeft">
                        <Link onClick={this.close} to="" aria-label="Ginkgo Mortgage Investment Corporation">
                            <img className="logo" src={logoClean} alt="" />
                        </Link>
                        <div className="iconWrap" onClick={() => this.setState({ open: !open })}>
                            <img src={open ? close : burger} alt="" />
                        </div>
                    </div>
                    {!isMobile || open ? (
                        <div>
                            {userType === "broker" && !location.pathname.includes("investor") ? (
                                <div className="mobileNavigationContent fadeIn">
                                    <Link
                                        onClick={this.close}
                                        to=""
                                        className={`menuItem ${location.pathname === "/" ? "active" : ""}`}
                                        href="/"
                                    >
                                        HOME
                                    </Link>
                                    <div className="menuItem dropdown">
                                        <span
                                            className={`menuItem target 
                                        ${
                                            location.pathname === "/aboutus" ||
                                            location.pathname === "/team" ||
                                            location.pathname === "/faq" ||
                                            location.pathname === "/appraisers"
                                                ? "active"
                                                : ""
                                        }`}
                                        >
                                            ABOUT US
                                            <div className="arrow"></div>
                                        </span>
                                        <div className="dropdownItems lightBackground">
                                            <Link
                                                onClick={this.close}
                                                to="/aboutus"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/aboutus" ? "active" : ""
                                                }`}
                                            >
                                                OUR STORY{" "}
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/team"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/team" ? "active" : ""
                                                }`}
                                            >
                                                TEAM{" "}
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/faq"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/faq" ? "active" : ""
                                                }`}
                                            >
                                                FAQ
                                            </Link>
                                        </div>
                                    </div>
                                    <Link
                                        onClick={this.close}
                                        to="/newsandevents"
                                        className={`menuItem ${location.pathname === "/newsandevents" ? "active" : ""}`}
                                    >
                                        NEWS & EVENTS
                                    </Link>
                                    <Link
                                        onClick={this.close}
                                        to="/products"
                                        className={`menuItem ${location.pathname === "/products" ? "active" : ""}`}
                                    >
                                        PRODUCTS
                                    </Link>

                                    <div className="menuItem dropdown">
                                        <span
                                            className={`menuItem target 
                                        ${
                                            location.pathname === "/appraisers" || location.pathname === "/appraisers"
                                                ? "active"
                                                : ""
                                        }`}
                                        >
                                            DEAL PROCESS
                                            <div className="arrow"></div>
                                        </span>
                                        <div className="dropdownItems lightBackground">
                                            <Link
                                                onClick={this.close}
                                                to="/submitadeal"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/submitadeal" ? "active" : ""
                                                }`}
                                            >
                                                DEAL PROCESS
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/appraisers"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/appraisers" ? "active" : ""
                                                }`}
                                            >
                                                {" "}
                                                APPRAISERS
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/lendingarea"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/lendingarea" ? "active" : ""
                                                }`}
                                            >
                                                {" "}
                                                LENDING AREA
                                            </Link>
                                        </div>
                                    </div>
                                    <Link
                                        onClick={this.close}
                                        to="/loyaltyprograms"
                                        className={`menuItem ${
                                            location.pathname === "/loyaltyprograms" ? "active" : ""
                                        }`}
                                    >
                                        LOYALTY PROGRAM
                                    </Link>
                                    <Link
                                        onClick={this.close}
                                        to="/contact"
                                        className={`menuItem ${location.pathname === "/contact" ? "active" : ""}`}
                                    >
                                        CONTACT
                                    </Link>
                                    {/* <SearchBox /> */}
                                </div>
                            ) : (
                                /* investor */
                                <div className="mobileNavigationContent fadeIn">
                                    <Link
                                        onClick={this.close}
                                        to= {this.state.langButton === 'CH' ? "/investor" : "/investor-ch"}
                                        className={`menuItem ${location.pathname === "/" ? "active" : ""}`}
                                    >
                                    {this.state.langButton === 'CH' ? 'HOME' : '首页'}    
                                    </Link>
                                    <Link
                                        onClick={this.close}
                                        to= {this.state.langButton === 'CH' ? "/investor/investment-products" : "/investor-ch/investment-products"}
                                        className={`menuItem ${location.pathname === "/products" ? "active" : ""}`}
                                    >
                                       {this.state.langButton === 'CH' ? 'PRODUCTS' : '产品'} 
                                    </Link>
                                    <div className="menuItem dropdown">
                                        <span
                                            className={`menuItem target 
                                        ${
                                            location.pathname === "/aboutus" ||
                                            location.pathname === "/team" ||
                                            location.pathname === "/faq" ||
                                            location.pathname === "/appraisers"
                                                ? "active"
                                                : ""
                                        }`}
                                        >
                                            {this.state.langButton === 'CH' ? 'INVESTMENT HUB' : '投资中心'}
                                            <div className="arrow"></div>
                                        </span>
                                        <div className="dropdownItems lightBackground">
                                            <Link
                                                onClick={this.close}
                                                to= {this.state.langButton === 'CH' ? "/investor/investment-hub" : "/investor-ch/investment-hub"}
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/investment-hub" ? "active" : ""
                                                }`}
                                            >
                                                {this.state.langButton === 'CH' ? 'INVESTMENT HUB' : '投资中心'}
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to= {this.state.langButton === 'CH' ? "/investor/how-to-invest" : "/investor-ch/how-to-invest"}
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/how-to-invest" ? "active" : ""
                                                }`}
                                            >
                                                {this.state.langButton === 'CH' ? 'How to Invest' : '如何投资'}
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to= {this.state.langButton === 'CH' ? "/investor/faq" : "/investor-ch/faq"}  
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/faq" ? "active" : ""
                                                }`}
                                            >
                                                {this.state.langButton === 'CH' ? 'FAQ' : '常见问题'}
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to= {this.state.langButton === 'CH' ? "/investor/what-is-mic" : "/investor-ch/what-is-mic"}
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/what-is-mic" ? "active" : ""
                                                }`}
                                            >
                                                {this.state.langButton === 'CH' ? "Whats a MIC" : "什么是房贷投资"}
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to= {this.state.langButton === 'CH' ? "/investor/investment-strategy" : "/investor-ch/investment-strategy"}  
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/investment-strategy"
                                                        ? "active"
                                                        : ""
                                                }`}
                                            >
                                                {this.state.langButton === 'CH' ? 'Investment Strateg' : '投资策略'}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="menuItem dropdown">
                                        <span
                                            className={`menuItem target 
                                        ${
                                            location.pathname === "/aboutus" ||
                                            location.pathname === "/team" ||
                                            location.pathname === "/faq" ||
                                            location.pathname === "/appraisers"
                                                ? "active"
                                                : ""
                                        }`}
                                        >   {this.state.langButton === 'CH' ? 'PORTFOLIO' : '资料目录'}
                                            <div className="arrow"></div>
                                        </span>
                                        <div className="dropdownItems lightBackground">
                                            <Link
                                                onClick={this.close}
                                                to= {this.state.langButton === 'CH' ? "/investor/our-performance" : "/investor-ch/our-performance"}
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/our-performance" ? "active" : ""
                                                }`}
                                            >
                                                {this.state.langButton === 'CH' ? 'Performance Portfolio' : '绩效投资组合'} 
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to= {this.state.langButton === 'CH' ? "/investor/analyst-rating" : "/investor-ch/analyst-rating"}
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/analyst-rating" ? "active" : ""
                                                }`}
                                            >
                                                {this.state.langButton === 'CH' ? 'Analyst Rating' : '分析师评级'}
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to= {this.state.langButton === 'CH' ? "/investor/loan-portfolio" : "/investor-ch/loan-portfolio"}
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/loan-portfolio" ? "active" : ""
                                                }`}
                                            >
                                                {this.state.langButton === 'CH' ? 'Loan Portfolio' : '贷款组合'}
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to= {this.state.langButton === 'CH' ? "/investor/fund-portfolio" : "/investor-ch/fund-portfolio"}
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/fund-portfolio" || location.pathname === "/investor-ch/fund-portfolio" ? "active" : ""
                                                }`}
                                            >
                                                {this.state.langButton === 'CH' ? 'Fund Portfolio' : '基金投资组合'}
                                                
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to= {this.state.langButton === 'CH' ? "/investor/historical-return" : "/investor-ch/historical-return"}
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/historical-return" ? "active" : ""
                                                }`}
                                            >
                                                {this.state.langButton === 'CH' ? 'Historical Returns' : '历史回报'}
                                                
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to= {this.state.langButton === 'CH' ? "/investor/lending-map" : "/investor-ch/lending-map"}
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/lending-map" ? "active" : ""
                                                }`}
                                            >
                                                {this.state.langButton === 'CH' ? 'Lending Map' : '贷款区域'}
                                            </Link>
                                            <Link
                                                onClick={(e) => {
                                                    this.close();
                                                    window.open("https://discover.ginkgomic.com/powerofsale", "_blank");
                                                }}
                                                to="/investor"
                                                className={`ddItem hoverPrimaryBackground`}
                                            >
                                                Power of Sale Listing
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="menuItem dropdown">
                                        <span
                                            className={`menuItem target 
                                        ${
                                            location.pathname === "/aboutus" ||
                                            location.pathname === "/team" ||
                                            location.pathname === "/faq" ||
                                            location.pathname === "/appraisers"
                                                ? "active"
                                                : ""
                                        }`}
                                        >
                                            {this.state.langButton === 'CH' ? 'NEWS & EVENTS' : '新闻和活动'}
                                            <div className="arrow"></div>
                                        </span>
                                        <div className="dropdownItems lightBackground">
                                        
                                            <Link
                                                onClick={this.close}
                                                to= {this.state.langButton === 'CH' ? "/investor/events" : "/investor-ch/events"}
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/events"
                                                        ? "active"
                                                        : ""
                                                }`}
                                            >
                                                {this.state.langButton === 'CH' ? 'News & Events' : '新闻和活动'}
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to= {this.state.langButton === 'CH' ? "/investor/gallery" : "/investor-ch/gallery"}
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/gallery" ? "active" : ""
                                                }`}
                                            >
                                                {this.state.langButton === 'CH' ? 'Gallery' : '画廊'}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="menuItem dropdown">
                                        <span
                                            className={`menuItem target 
                                        ${
                                            location.pathname === "/aboutus" ||
                                            location.pathname === "/team" ||
                                            location.pathname === "/faq" ||
                                            location.pathname === "/appraisers"
                                                ? "active"
                                                : ""
                                        }`}
                                        >
                                            {this.state.langButton === 'CH' ? 'ABOUT US' : '关于我们'}
                                            <div className="arrow"></div>
                                        </span>
                                        <div className="dropdownItems lightBackground">
                                            <Link
                                                onClick={this.close}
                                                to= {this.state.langButton === 'CH' ? "/investor/about-us" : "/investor-ch/about-us"}
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/about-us" ? "active" : ""
                                                }`}
                                            >
                                                {this.state.langButton === 'CH' ? 'About Us' : '关于我们'} 
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to= {this.state.langButton === 'CH' ? "/investor/our-story" : "/investor-ch/our-story"}
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/our-story" ? "active" : ""
                                                }`}
                                            >
                                                {this.state.langButton === 'CH' ? 'Our Story' : '我们的故事'}
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to= {this.state.langButton === 'CH' ? "/investor/team" : "/investor-ch/team"}
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/team" || location.pathname === "/investor-ch/team" ? "active" : ""
                                                }`}
                                            >
                                                {this.state.langButton === 'CH' ? 'Team' : '团队'}
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to= {this.state.langButton === 'CH' ? "/investor/why-us" : "/investor-ch/why-us"}
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/why-us" ? "active" : ""
                                                }`}
                                            >
                                                {this.state.langButton === 'CH' ? 'Why Us' : '为什么选择我们'}
                                            </Link>
                                        </div>
                                    </div>
                                    <Link
                                        onClick={this.close}
                                        to= {this.state.langButton === 'CH' ? "/contact" : "/investor-ch/contact"}
                                        className={`menuItem ${location.pathname === "/contact" ? "active" : ""}`}
                                    >
                                        {this.state.langButton === 'CH' ? 'CONTACT' : '联系我们'}
                                    </Link>
                                    {/* <SearchBox /> */}
                                </div>
                            )}
                        </div>
                    ) : (
                        <span></span>
                    )}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setUserType: userType => {
            if (userType !== "broker" && userType !== "investor") throw "User type must be a broker or an investor";
            dispatch({
                type: "SET_USER_TYPE",
                payload: userType
            });
        }
    };
}

function mapStateToProps(state) {
    return {
        ...state.app
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrimaryNavigation));