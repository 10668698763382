import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import "./Header.css";

const Header = props => (
    <div className="Header lightText withBackground centerText">
        <H1 className="fontSizeLL">什么是房贷投资MIC？</H1>
        <div className="marginOnMobile paddingTopM fontWeightNormal plainText fontSizeM lineHeightM fadeInUp">
        如何通过MIC实现财富增值？<br></br>MIC是一种房贷投资基金，提供了绝佳的机会，让您的资金为您创造理想生活或构建可靠的退休投资策略。
        </div>
    </div>
);

export default pure(Header);
