import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import "./Header.css";

const Header = props => (
    <div className="Header lightText withBackground centerText">
        <H1 className="fontSizeLL">投资策略</H1>
        <div className="marginOnMobile paddingTopM fontWeightNormal plainText fontSizeM lineHeightM fadeInUp">
            这是我们如何最小化风险并为您提供稳定、持续收入的高回报的方式。
        </div>
    </div>
);

export default pure(Header);
