import React from 'react'
import { pure } from 'recompose'
import ReactPlayer from 'react-player'
import './VideoSection.css'
import { Carusel }  from '../../../../Molecules'  
import CorpVideo from './Corp Video Eng.mp4'
import CorpVideoC from './Corp Video Chn.mp4'
import { H1 } from '../../../../Atoms'

const VideoSection = props => {

    const isMobile = window.innerWidth < 800

    return (
        <div className='VideoSection'>
{/*             <div className={`ourStory fadeInUp darkBackground lightText ${!isMobile ? 'paddingXL pageMargin roundedBig' : 'paddingL'}`}>
 */}                <div className={!isMobile ? 'paddingRightXL paddingLeftXL' : ''}>
                    <H1 className={`centerText ${!isMobile ? 'paddingBottomM' : ''}`}>{window.location.pathname.includes('/investor-ch') ? '我们的故事' : 'Our Story'}</H1>
                </div>
{/*             </div>
 */}            <div className='player-wrapper' >
                <ReactPlayer url={window.location.pathname.includes('/investor-ch') ? CorpVideoC : CorpVideo}
                className='react-player fixed-bottom'
                width='100%'
                height='100%'
/*                 volume='1'
                muted={false}
 */                playing={false}
                controls={true}
                />
            </div>

        </div>
           )
}

export default pure(VideoSection)