import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import "./Header.css";

const Header = props => (
    <div className="Header lightText withBackground centerText">
        <H1 className="fontSizeLL">新闻与活动</H1>
        <div className="paddingTopM fontWeightNormal plainText fontSizeM lineHeightM fadeInUp marginOnMobile">
            <p>随时了解您的盈信投资！阅读我们的最新公告并参加我们的研讨会。</p>
        </div>
    </div>
);

export default pure(Header);
