import React, { PureComponent } from 'react'
import { TextField, Button, H2, Dropdown, AppLoader } from '../../Atoms'
import { OnScrollAnimatior } from '..'
// import { baseURL } from '../../API'
import './ContactForm.css'
import axios from 'axios'
import { opaqueType } from '@babel/types';
import ReactGA from 'react-ga';

class ContactForm extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        isLoading: null,
        sent: null,
        errors: {}
    }

    values = {}


    fieldChange = function (name, value) {
        this.values[name] = value
    }.bind(this)

    submit = function () {
        const {
            email,
            firstName,
            lastName,
            categoryId,
            message
        } = this.values

        if (!this.validateEmail(email))
            return this.setState({ errors: { email: 'Email is not valid' } })
        else if (this.state.errors.email)
            this.setState({ errors: {}, isLoading: true })
        else
            this.setState({ isLoading: true })
        
        ReactGA.event({       
            category: "contact information",  // Required
            action: "submit contact",       // Required
            //label: 'labelName',       
            //value: 10,       
            //nonInteraction: false     
        });   

        // axios.post(`${baseURL}/api/submit-form/contact`, {
        axios.post('/api/forms/contact', {
            last: lastName,
            first: firstName,
            email: email,
            categoryId: categoryId,
            msg: message,
            source: window.location.href,
        }).then((response) => {
          this.setState({ isLoading: false })
          this.setState({sent: true })
        }, (error) => {
          console.log(error);
          this.setState({ isLoading: false })
          alert('Something went wrong, please try again later.');
        });
        
    }.bind(this)

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    componentDidMount() {
        this.fieldChange('categoryId', 1)

    }

    render() {
        const { fieldChange, state } = this
        const isMobile = window.innerWidth < 800

        if (state.isLoading)
            return (
                <div className='ContactForm centerText primaryBackground pagePadding paddingTopXL paddingBottomXL' {...this.props}>
                    <AppLoader />
                </div>
            )
        else if (state.sent)
            return (
                <div className='ContactForm centerText primaryBackground pagePadding paddingTopXL paddingBottomXL' {...this.props}>
                    <H2 className='gold'>{(window.location.pathname.includes('/investor-ch') ) ? '谢谢' : 'Thank you!'}</H2>
                    <div>
                    {window.location.pathname.includes('/investor-ch')? '我们会尽快回复您。' : 'We will reach back shortly.'}
                    </div>
                </div>
            )
        else
            return (
                <div className='ContactForm centerText primaryBackground pagePadding paddingTopXL paddingBottomXL' {...this.props}>
                    <div className={isMobile ? '' : 'paddingRightXL paddingLeftXL'}>
                        <OnScrollAnimatior animation='tada'>
                            <H2>{window.location.pathname.includes('/investor-ch')? '您好!' : 'Say Hello!'}</H2>
                        </OnScrollAnimatior>
                        <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }} >
                            <div className='paddingTopM paddingBottomL fontSizeM'>
                            {window.location.pathname.includes('/investor-ch') ? '无论您有问题、鼓励的话语或疑虑，我们都很乐意倾听并提供帮助。' : 'Whether you have a question, a kind word, or concern, we would be happy to hear from you and help.'}
                        </div>
                        </OnScrollAnimatior>
                        <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }} >
                            <div className='ContactFormRow'>
                                <TextField
                                    label={window.location.pathname.includes('/investor-ch')? '名字' : 'First Name'}
                                    onChange={value => fieldChange('firstName', value)}
                                />
                                <TextField
                                    label={window.location.pathname.includes('/investor-ch')? '姓氏' : 'Last Name'}
                                    onChange={value => fieldChange('lastName', value)}
                                />
                            </div>
                            <div className='ContactFormRow'>
                                <TextField
                                    error={this.state.errors.email}
                                    label={window.location.pathname.includes('/investor-ch')? '电子邮件地址' : 'Email Address'}
                                    onChange={value => fieldChange('email', value)}
                                />
                                <Dropdown
                                    label={window.location.pathname.includes('/investor-ch')? '类别' : 'Category'}
                                    onChange={value => fieldChange('categoryId', value)}
                                />
                            </div>
                            <div className='ContactFormTextarea'>
                                <TextField
                                    label={window.location.pathname.includes('/investor-ch')? '您的信息' : 'Your Message'}
                                    onChange={value => fieldChange('message', value)}
                                    textarea
                                />
                            </div>
                            <Button onClick={this.submit }>{window.location.pathname.includes('/investor-ch')? '发送' : 'SEND'}</Button>   

                        </OnScrollAnimatior>
                    </div>
                </div>
            )
    }
}

export default ContactForm