import React from 'react'
import { Carusel } from '../../../../Molecules'
import { H1, Button } from '../../../../Atoms'
import { pure } from 'recompose'
import './Header.css'
import background4img from './background4.webp'
import mobilebackground4img from './mobilebackground4.png'
import background1img from './background.webp'
import mobilebackground1img from './mobilebackground.png'
import background2img from './background2.webp'
import mobilebackground2img from './mobilebackground2.png'
import background3img from './background3.webp'
import mobilebackground3img from './mobilebackground3.png'

const isMobile = window.innerWidth < 800
const Header = props => (
    <div className='Header primaryBackground'>
        <Carusel
            nav
        >

            <div className='caruselItem withBackground slideFour'>
                {/*                 <H1 className='fadeIn'>
                    Ginkgo MIC 
                    <br />Deal Tracker App!
                </H1>
                <div className='fontSizeM paddingTopM fadeIn'>
                    Manage your deals, check your Power Agent status, contact your Solution Advisor all from within the new Ginkgo MIC Deal Tracker App! 
                </div>
                <br />

 */}
                <img fetchpriority="high" src={isMobile ? mobilebackground4img : background4img} alt='Ginkgo MIC Deal Tracker App' />

                <a target="_blank" href='https://ginkgomic.com/newsandevents' aria-label='news and events'><Button>READ MORE</Button></a>
            </div>

            <div className='caruselItem withBackground slideOne '>
                {/*                 <H1 className='fadeIn'>
                    Welcome to
                    <br /> Ginkgo MIC
                </H1>
                <div className='fontSizeM paddingTopM fadeIn paddingBottomM'>
                    If you are a mortgage agent looking to help your clients achieve their real estate dreams through alternative sources of financing, you have come to the right place!
                </div>
                <br />
                <div><h2><b>We save your deals!</b></h2></div>
 */}

                <img fetchpriority="high" src={isMobile ? mobilebackground1img : background1img} alt='Ginkgo MIC Deal Tracker App' />
                <a href="/submitadeal" aria-label='submit a deal'><Button>Learn More</Button></a>
            </div>

            <div className='caruselItem withBackground slideThree'>
                {/*                 <H1 className='fadeIn'>
                    Your VACATION
                    <br />is on us!
                </H1>
                <div className='fontSizeM paddingTopM fadeIn paddingBottomM'>
                    We have one of the best loyalty programs in the industry: not only do agents get lower rates, but great tech and a FREE VACATION
                </div>
 */}
                <img fetchpriority="low" src={isMobile ? mobilebackground3img : background3img} alt='Ginkgo MIC Deal Tracker App' />
                <a href='/loyaltyprograms' aria-label='loyaly programs'><Button>Learn More</Button></a>
            </div>

            <div className='caruselItem withBackground slideTwo'>
                {/*                 <H1 className='fadeIn'>
                    Stay on top
                    <br /> of the game
                </H1>
                <div className='fontSizeM paddingTopM fadeIn paddingBottomM'>
                    We offer great in-person and online workshops to ensure agents get the most out of private lending.
                </div>
                <div className='fontSizeM paddingTopM fadeIn'>Book a private one or come into our open house sessions!
                </div>
                <br />
 */}
                <img fetchpriority="low" src={isMobile ? mobilebackground2img : background2img} alt='Ginkgo MIC Deal Tracker App' />
                <a target="_blank" href='https://go.pardot.com/l/361851/2019-10-08/6tqws' aria-label='Ginkgo MIC Deal Tracker App'><Button>Learn More</Button></a>
            </div>
        </Carusel>
    </div>
)

export default pure(Header)