import React, { useState }  from "react";
import { pure } from "recompose";
import { H1, Button } from "../../../../../../Atoms";
import { OnScrollAnimatior } from "../../../../../../Molecules";
import "./RatingGraphSection.css";
import ReviewCard from "../../../../../../Molecules/Investor/ReviewCard/ReviewCard";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Select, MenuItem} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(0),
      minWidth: 120,
    },
    selectEmpty: {
      marginRight: theme.spacing(1),
      lineHeight: '27px',
    },
  }));
  
const reviews = [
    {
        name: <span>Daniel Oh</span>,
        title: <span>Google Reviews</span>,
        text: (
            <span>
                他们非常友好，并关心他们的投资者。其中让我印象深刻的一点是他们在运营上的透明度。没有隐瞒，没有欺骗。
            </span>
        )
    },
    {
        name: <span>Addison Cham</span>,
        title: <span>Google Reviews</span>,
        text: (
            <span>
                已经与他们合作投资6年，一直获得稳定的回报，员工非常乐于助人。每个月收到关于利息回报的短信是我非常期待的事情。
            </span>
        )
    }
];

const RatingGraphSection = props => {
    const [year, setYear] = useState(2025);
    const handleChange = (event) => {
      setYear(event.target.value);
    };
    const classes = useStyles();
    const isMobile = window.innerWidth < 800;

    const ReviewCards = () =>
        reviews.map(({ name, title, text }, i) => (
            <Grid item xs={6}>
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                >

                    <ReviewCard name={name} title={title} text={text} />
                </OnScrollAnimatior>
            </Grid>
        ));
        // onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
        // KL: 2025-01-27 - removed console.log from OnScrollAnimation element above
    return (
        <div className="pagePadding-Investor paddingTopXL RatingGraphSection centerText marginOnMobile">
            <H1 className="fontSizeLL middleHeader">Ginkgo盈信 “持续创造高于平均水平的收益”</H1>
            <div className="paddingTopM fontWeightBold plainText fontSizeXS lineHeightM fadeInUp">
            由独立研究机构 Fundamental Research Corporation 提供的分析
            </div>
            <div className="paddingTopL paddingBottomL fontSizeM fontWeightNormal lineHeightM captionSection">
            我们很高兴再次宣布，Ginkgo盈信连续第四期获得了 Fundamental Research Corp.（FRC）授予的整体“2”评级，表明其具有非常良好的回报与风险比。FRC 是全球最大的独立研究机构之一，拥有超过17年的历史，已覆盖550多家公司。FRC 的使命是帮助投资者做出明智的投资决策，并在2021年被评为全球130多家投资机构中的“第一研究公司”。

            FRC 预计，加拿大央行将在2024年上半年启动降息，这主要受到失业率上升、金融不稳定、抵押贷款成本增加、消费者信心下降以及通胀冷却的推动。他们预测，由于利率降低，今年的交易量将有所增加。在这些下降趋势下，FRC 认为Ginkgo盈信具有越来越大的吸引力，预计2024财年的收益率将达到9.2%。

            Ginkgo盈信始终致力于为投资者提供稳定的分红，自成立以来已分发超过4,500万加元的分红。最新的FRC报告进一步验证了我们提供安全稳定投资的承诺，加强了我们为利益相关方提供信任和价值的信心。

            恭喜Ginkgo团队出色的表现！
            </div>
            <div className="paddingTopM paddingBottomXL">
                <FormControl >
                    <Select
                          value = {year}
                          onChange={handleChange}
                          className={classes.selectEmpty}
                        >
                          <MenuItem value={2019}>2019</MenuItem>
                          <MenuItem value={2021}>2021</MenuItem>
                          <MenuItem value={2022}>2022</MenuItem>
                          <MenuItem value={2024}>2024</MenuItem>
                          <MenuItem value={2025}>2025</MenuItem>
                    </Select>
                </FormControl>
                    <Button
                        onClick={() => {
                            if (year == 2025){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EThxVKOJEalGgrUUk7UGNnMB18gXSMn5eBVPPFIsLTVssA?e=zBl3va"
                                );
                            };
                            if (year == 2024){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EUaXelKKCdlFuKhYE5tgmXoBA_AtgqDExC0Jed2MZQ7qGg?e=2eENHL"
                                );
                            };
                            if (year == 2022){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EdM0BQlWBadHhxgsk2TOYFcBaPV4D7PuJZ7Y6YurM-TiyQ"
                                );
                            };
                            if (year == 2021){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EXQmldGRVeJCrkq__eH44psBvZtW4GmWfGg1kQBRaOxAVg?e=Cng0Kb"
                                );
                            };
                            if (year == 2019){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EVqyONN-Jf5VjXN4beDzDGYBCriNabTcydNnYyF61AI_OQ?e=bELbFF"
                                );
                            }}}
                            >请参阅报告
                    </Button>
             </div>

            <div className="paddingBottomXL">
                <Grid container direction="row">
                    <Grid item xs={4}>
                        <H1 className="leftText fontSizeLL paddingBottomL">为什么投资者钟爱Ginkgo</H1>
                    </Grid>
                    <Grid className="paddingLeftM" item xs={8}>
                        <Grid container>{ReviewCards()}</Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default pure(RatingGraphSection);
