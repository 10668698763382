import React from "react";
import { pure } from "recompose";
import { H2, Button } from "../../../../../../Atoms";
import "./GraphSection.css";
import moment from "moment"

import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  selectEmpty: {
    marginRight: theme.spacing(1),
    lineHeight: '27px',
  },
}));


const GraphSection = props => {

const classes = useStyles();
const [month, setMonth] = React.useState( moment().date()>=15 ? moment().subtract(1, 'months').month() + 1 : moment().subtract(2, 'months').month() + 1);
const [year, setYear] = React.useState( month===11 && moment().date()<=14 ? moment().subtract(1, 'years').year() : month===12 ? moment().subtract(1, 'years').year() : moment().year());

const handleChange = (event) => {
    setMonth(event.target.value);
};

const handleYearChange = (event) => {
    setYear(event.target.value);
};

return(
    <div className="pagePadding-Investor paddingTopXL paddingBottomXXL GraphSection">
        <Grid className="PieChartSection" container alignItems="center" direction="row">
            <Grid item xs={8}>
                <H2 className="fontSize28">Read this Month's Fund Report:</H2>
                <div className="paddingTopS fontWeightNormal fontSizeS lineHeightM fadeInUp">
                    We'll let the numbers speak for themselves.
                </div>
            </Grid>
            <Grid item xs={4}>
                <div className="paddingTopL">

                    <FormControl className={classes.formControl}>
                    <div>
                        <Select
                          value = {year}
                          onChange={handleYearChange}
                          //displayEmpty
                          className={classes.selectEmpty}
                          //inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value={2021}>2021</MenuItem>
                          <MenuItem value={2022}>2022</MenuItem>
                          <MenuItem value={2023}>2023</MenuItem>
                          <MenuItem value={2024}>2024</MenuItem>
                          <MenuItem value={2025}>2025</MenuItem>
                        </Select>
                        <Select
                          value = {month}
                          onChange={handleChange}
                          //displayEmpty
                          className={classes.selectEmpty}
                          //inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value={1}>January</MenuItem>
                          <MenuItem value={2}>February</MenuItem>
                          <MenuItem value={3}>March</MenuItem>
                          <MenuItem value={4}>April</MenuItem>
                          <MenuItem value={5}>May</MenuItem>
                          <MenuItem value={6}>June</MenuItem>
                          <MenuItem value={7}>July</MenuItem>
                          <MenuItem value={8}>August</MenuItem>
                          <MenuItem value={9}>September</MenuItem>
                          <MenuItem value={10}>October</MenuItem>
                          <MenuItem value={11}>November</MenuItem>
                          <MenuItem value={12}>December</MenuItem>
                        </Select>
                    </div>
                    </FormControl>

                    <Button
                        onClick={() => {
                            if((month == 1) && (year == 2025)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/ETo91Wa4Bb5Jnf0vUy5eTeEBBx90dLs3M2ICeas7NHYOYg?e=YEpRzw"
                                );
                            };
                            if((month == 2) && (year == 2025)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EfuA6RxxFJdEmy9O2i8z_UkBKLt_KDp9YfFf1ep0ux3C5g?e=TIuxGr"
                                );
                            };
                            if((month == 3) && (year == 2025)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/Ef-qVkRtr49Hg6S2DpVlznoBnJ92Qk9KDj0Q2Wbq-VHEMw?e=qdE4LO"
                                );
                            };
                            if((month == 4) && (year == 2025)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/ESZuCxUYUKBBu-J984WxBqABUNQsJX59Xz_sX0eiv4C9Xw?e=J2rEzd"
                                );
                            };
                            if((month == 5) && (year == 2025)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EWBClARpQ71PoSwyzGX3uhgBHoa4rID7kt9S3ndHPjE_mw?e=d5VAKF"
                                );
                            };
                            if((month == 6) && (year == 2025)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EazArM9B-hNNroICWzYxuJUBOTq2m6NEnZwRy9v_sosM3w?e=GEfd4Z"
                                );
                            };
                            if((month == 7) && (year == 2025)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EVw1cJocyDlDmHr-fOtg_SkB17AIMiF-BcSUlDJu4KV2Rw?e=6h1rgo"
                                );
                            };
                            if((month == 8) && (year == 2025)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EfjUl-_AsnhJmCwOqNGzpfwBCrN4K009ULjQaM9zLmLtmA?e=dVPeot"
                                );
                            };
                            if((month == 9) && (year == 2025)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EQAzTyB09qlFhbScK4dOyC8BQn4BbDcijgYhyvP7edqUQg?e=aclOZo"
                                );
                            };
                            if((month == 10) && (year == 2025)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/ETXt_oU8mvVBs6WemoZS9AYBbP9aINL_GYYQwR4hRBNfVA?e=mQWrS2"
                                );
                            };
                            if((month == 11) && (year == 2025)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/ESOsulkHsiJOqrw9S7xUIFAB2H1xUeyncbgwGzCrWExxUA?e=xDOwoF"
                                );
                            };
                            if((month == 12) && (year == 2025)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EVXfA9If2K9PiNqV9pKg0YUBEINl9w3YiMTu1NQMmg2GKg?e=0A0tv9"
                                );
                            };


                            if((month == 1) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/ETA0WtdeLVtFsVSAbgblw0MBTEPLMaiOj3NcNHoPlv-h7A?e=3nuPs6"
                                );
                            };
                            if((month == 2) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EYbh0S11ZQdAhAgsSp8KpHkBpeByft7eoudiD6onFgPW5g?e=IJ4ckM"
                                );
                            };
                            if((month == 3) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EY1b1bDFGWpAkHwyJ6t5efMBWdKv0bBiqwhMTP0oLbSsug?e=Ycsi2c"
                                );
                            };
                            if((month == 4) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/ESwp8bqqg-5GiEwkAf6is5UBIZiw1SEBg7fwcwCKn_d0TA?e=mL5Rfn"
                                    
                                );
                            };
                            if((month == 5) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EXlMFYg2F69Agg5Om1IvkrYBOU2loPGbJwbg8HGI_t92QA?e=aqLfQS"
                                );
                            };
                            if((month == 6) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EYNgpDh2YBNPmbKhfqV5e0oBwOmqa4hcD11SOP89pQfJBg?e=4FQeHh"
                                );
                            };
                            if((month == 7) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/Efr8DaDJx0NOq4sP2u3b_-gB-Ik3Le6u2RE-IZdT6ppLow?e=2udq6P"
                                );
                            };
                            if((month == 8) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EahzcJVXiiRFu9j7itT-iIwBUoPzf1ggwMSfjNSRyBoEtQ?e=Nf70Da"
                                );
                            };
                            if((month == 9) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EYsOg9SIEQ5Ileqj9WZ4OdcBNkkTdx1IXP5cTF5hDoOD4w?e=h1y3ac"
                                );
                            };
                            if((month == 10) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EVENNFxlJVdGnrhxqrjFWZABQ8UnAsaGMD9yAjWIWv2kmQ?e=RNZMDr"
                                );
                            };
                            if((month == 11) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EfxfH-CAlPtEpCRxsCUOjNoBL9YxcTWu-5UG15qgym6FKw?e=7XBcRz"
                                );
                            };
                            if((month == 12) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EXE1XI-VIUFFmb4LFHOgOhABoqt6lOtIcQi0U_A_1ryKkQ?e=GuuHu1"
                                );
                            };


                            if((month == 1) && (year == 2023)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EQaIG5ailXtGlrMiYFQ23lUBIbduBfnqc5bYtnIPaOd51Q"
                                );
                            };
                            if((month == 2) && (year == 2023)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/Ef0IPmWXNnlGguoTw1Yzj1MBw_shqhvI5JRd5fQBLCHpzA"
                                );
                            };
                            if((month == 3) && (year == 2023)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EeNOQiOM2zVKgjyITPFXJNcBY3U7gFadCq5uHqx9Clmp_g"
                                );
                            };
                            if((month == 4) && (year == 2023)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EbbTmWd5R75Bu8YP3SjYGPcBy8k3xWrKGDRUZ4ASf8mmqw"
                                );
                            };
                            if((month == 5) && (year == 2023)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EdycURYcpedPg41yDD_L0LIBhovjxN1IgVGrcYmvBKU88Q"
                                );
                            };
                            if((month == 6) && (year == 2023)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/ESMdNLALN6xCo-qVM5w11ssBUvWLDyx85KbeIwbuUw3uHg"
                                );
                            };
                            if((month == 7) && (year == 2023)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EUDMLU9uIqNBnwHS1daISigBxl-cK264MTAwGr4sytHDhg"
                                );
                            };
                            if((month == 8) && (year == 2023)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EbK1BT8DHGFGkWyFhK8AP1IBpyPryyTNsO-2erf8AwuEbA"
                                );
                            };
                            if((month == 9) && (year == 2023)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EdSZiJxCBqVAm8a0eIhsH7UBqzCQvH9J9bXqPOouTrA6pw"
                                );
                            };
                            if((month == 10) && (year == 2023)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/ERz9A2-Gf15LiPbvbtnH1e0BBgSKXfe_R722-liEisb-Uw"
                                );
                            };
                            if((month == 11) && (year == 2023)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EVMFNLGF_VNPlD0kryF00PMBcCyx__cHizbozJ3xrrTYKQ"
                                );
                            };
                            if((month == 12) && (year == 2023)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EWz9XNlqSDtAkGb9akcbucEBiqUofXys8lGMRC3YY8EqZQ"
                                );
                            };

                            if((month == 1) && (year == 2022)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EUVvtk_0K9tHsqSrsWanZjcBNW4MEDY3iPvekFr62qaD8g"
                                );
                            };
                            if((month == 2) && (year == 2022)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/Ed3KxFJslqxDgKNjYw6SRXMBBP8NcTpWUrj7pxoWraefNA"
                                );
                            };
                            if((month == 3) && (year == 2022)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EYi17ssDRWNPo0ynFfLfNUUBeBin6BbqDk2ySa1XmH39Fg"
                                );
                            };
                            if((month == 4) && (year == 2022)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EX-ifM4N94BNrFOvo4BHTToBct5IjqTr8vUhMBNYa-pocQ"
                                );
                            };
                            if((month == 5) && (year == 2022)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EThyNn7LjIhJjBSAlTFIPPABypa-snA9LPbIyMC8b2M2XA"
                                );
                            };
                            if((month == 6) && (year == 2022)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/Ed5m7lzLBndNnKFmWYJjpawB_rw0L_77b9em-UuPPcITRQ"
                                );
                            };
                            if((month == 7) && (year == 2022)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/ES7Ti744uqFHqN6z1k249isBnvqcg2zLo0mwIQ4Kx86fEA"
                                );
                            };
                            if((month == 8) && (year == 2022)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EVit98jzpB5Bl4KDonZSxiQBRSUhmclPzMvvBy8DmeNYsQ"
                                );
                            };
                            if((month == 9) && (year == 2022)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/ERxI--X7QO1AkgUrqfHq4OEBGmOpD-Ywc1UZHCWJKivbuA"
                                );
                            };
                            if((month == 10) && (year == 2022)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/Eb4N22FLBEdGsRDk6_MrCpQBykLTDpljBrNMTuu5nc_EQQ"
                                );
                            };
                            if((month == 11) && (year == 2022)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EYIMlzmCY-1EuAzMdwLbzv4BeIk5rAl0s0stD7LtlIvnfQ"
                                );
                            };
                            if((month == 12) && (year == 2022)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/Ed_hQRgypdhGq7djunKU1wgBQ9QVrC3DvYk8gFaScOjZlg"
                                );
                            };
                            if((month == 1) && (year == 2021)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EbxHH_Z9ce5OrHu33wQhKkcBRziA18MDhHWZpjucvta3ug"
                                );
                            };
                            if((month == 2) && (year == 2021)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EYYhj3HKtjNBiO47BH1X4goBk0EixCKt7WruHkvG5UJbBg"
                                );
                            };
                            if((month == 3) && (year == 2021)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/ER2t8Laj4NhKiHFWsw4tRr0B8a3PH8RTcCmx50rUXFsPiQ"
                                );
                            };
                            if((month == 4) && (year == 2021)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EW5RUiAi4oJNs6Fb9-boUNEB1CIRV_iavkZFPdUUeqvvzw"
                                );
                            };
                            if((month == 5) && (year == 2021)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EaUAwkH27bJBgh_uhIFwSPcB8pUgygq1Vut_5evbL80wBA"
                                );
                            };
                            if((month == 6) && (year == 2021)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/ERMDH447eiZPrxVqtykYp0wBNv1O0UoGH3-kXlMhkAFAHA"
                                );
                            };
                            if((month == 7) && (year == 2021)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EenGABlemLpAi_IyDupgoFwB1ODlzuZh4jjJMvITcQXUMg"
                                );
                            };
                            if((month == 8) && (year == 2021)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/ETBwoalrR5JElPc1Xi_kK7ABrn5QzhktAhEPDxEqY9VOqQ"
                                );
                            };
                            if((month == 9) && (year == 2021)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/ETkyisA848ZNgS6XLKVy-ScBkOYH0ZdQldsaCkrLf8I1nw"
                                );
                            };
                            if((month == 10) && (year == 2021)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EcJsfNH6I_hOj-uYJ18FQaUBgi5wArPc6ZDiFDyPEOUOVQ"
                                );
                            };
                            if((month == 11) && (year == 2021)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/ETJRgyxUyWBGpoHYSk4qFK4BqRZHobTlcazZ-mSnQP4vdQ"
                                );
                            };
                            if((month == 12) && (year == 2021)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/ER3-6OpTifZLiqnMVnE45k8Bti_-yYbzxtnG1Dn4gj3XVQ"
                                );
                            }   
                            if((month == 1) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/34SWGSY"
                                );
                            };
                            if((month == 2) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/3u7ZFQ5"
                                );
                            };
                            if((month == 3) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/37CeWku"
                                );
                            };
                            if((month == 4) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/3699e98"
                                );
                            };
                            if((month == 5) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/3qbFuzz"
                                );
                            };
                            if((month == 6) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/3KRjeTu"
                                );
                            };
                            if((month == 7) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/3IhbLeK"
                                );
                            };
                            if((month == 8) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/3IgHson"
                                );
                            };
                            if((month == 9) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/34MLDum"
                                );
                            };
                            if((month == 10) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/3u82Kj7"
                                );
                            };
                            if((month == 11) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/3ibw6aH"
                                );
                            };
                            if((month == 12) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/3IfQn9p"
                                );
                            }   
                        }}
                        className="leftButton"
                    >
                        SEE REPORT
                    </Button>
                </div>
            </Grid>
        </Grid>
    </div>
);
}

export default pure(GraphSection);
