import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../Atoms";
import "./PastPortfolioSection.css";

import Grid from "@material-ui/core/Grid";

const PastPortfolioSection = props => (
    <div className="PastPortfolioSection withBackground lightText">
        <Grid container direction="row" justify="center">
            <Grid item xs={7}></Grid>
            <Grid item xs={5}>
                <div className="innerContent paddingTopL">
                    <H1 className="fontSizeLL paddingTopXXL">{window.location.pathname.includes('/investor-ch') ? '过去的投资组合报告' : 'Past Portfolio Reports'}</H1>
                    <div className="paddingTopS fontWeightNormal plainText fontSizeS lineHeightM fadeInUp">
                    {window.location.pathname.includes('/investor-ch') ? '浏览我们的每月业绩报告。当您对我们的披露信息感到满意并准备好时，请给我们来电！' : 'Browse through our monthly performances. When you\'re confident with our disclosures and ready to  invest, give us a call!'}
                    </div>
                    <div className="buttonRow">
                        <a href = "mailto: info@ginkgomic.com" target="_blank">{window.location.pathname.includes('/investor-ch') ? '发送邮件' : 'Send Email'}</a>
                    </div>
                </div>
            </Grid>
        </Grid>
    </div>
);

export default pure(PastPortfolioSection);
