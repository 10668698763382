import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import {
    Home,
    AboutUs,
    NewsAndEvents,
    Products,
    LoyaltyPrograms,
    Contact,
    ContactCH,
    SubmitADeal,
    BlogFeed,
    BlogPost,
    OurTeam,
    Appraisers,
    LendingArea,
    FAQ
} from "../Pages";

import {
    InvestorHome,
    InvestorHomeCH,
    InvestorInvestmentHub,
    InvestorInvestmentHubCH,
    InvestorHowToInvest,
    InvestorHowToInvestCH,
    InvestorFAQ,
    InvestorFAQCH,
    InvestorWhatIsMIC,
    InvestorWhatIsMICCH,
    InvestorInvestmentStrategy,
    InvestorInvestmentStrategyCH,
    InvestorInvestmentProducts,
    InvestorInvestmentProductsCH,
    InvestorNewsAndEvents,
    InvestorNewsAndEventsCH,
    InvestorEventsAndSeminars,
    InvestorEventsAndSeminarsCH,
    InvestorGallery,
    InvestorGalleryCH,
    InvestorAnalystRating,
    InvestorAnalystRatingCH,
    InvestorLendingMap,
    InvestorLendingMapCH,
    InvestorHistoricalReturn,
    InvestorHistoricalReturnCH,
    InvestorLoanPortfolio,
    InvestorLoanPortfolioCH,
    InvestorFundPortfolio,
    InvestorFundPortfolioCH,
    InvestorOurPerformance,
    InvestorOurPerformanceCH,
    InvestorAboutUs,
    InvestorAboutUsCH,
    InvestorOurStory,
    InvestorOurStoryCH,
    InvestorTeam,
    InvestorTeamCH,
    InvestorWhyUs,
    InvestorWhyUsCH
} from "../Pages";

import { PrimaryNavigation, Footer } from "../Molecules";

const Router = () => (
    <BrowserRouter className="Router">
        <PrimaryNavigation />
        <Route path="/" exact component={Home} />
        <Route path="/aboutus" component={AboutUs} />
        <Route path="/newsandevents" component={NewsAndEvents} />
        <Route path="/products" component={Products} />
        <Route path="/loyaltyprograms" component={LoyaltyPrograms} />
        <Route path="/submitadeal" component={SubmitADeal} />
        <Route path="/contact" component={Contact} />
        <Route path="/investor-ch/contact" component={ContactCH} />
        <Route path="/blogfeed" component={BlogFeed} />
        <Route path="/team" component={OurTeam} />
        <Route path="/appraisers" component={Appraisers} />
        <Route path="/lendingarea" component={LendingArea} />
        <Route path="/faq" component={FAQ} />

        <Route path="/blogpost/:id" component={BlogPost} />

        <Route path="/investor/" exact component={InvestorHome} />
        <Route path="/investor-ch/" exact component={InvestorHomeCH} />

        <Route path="/investor/investment-products" component={InvestorInvestmentProducts} />
        <Route path="/investor-ch/investment-products" component={InvestorInvestmentProductsCH} />

        <Route path="/investor/investment-hub" component={InvestorInvestmentHub} />
        <Route path="/investor-ch/investment-hub" component={InvestorInvestmentHubCH} />
        <Route path="/investor-ch/how-to-invest" component={InvestorHowToInvestCH} />
        <Route path="/investor/how-to-invest" component={InvestorHowToInvest} />
        <Route path="/investor/faq" component={InvestorFAQ} />
        <Route path="/investor-ch/faq" component={InvestorFAQCH} />
        <Route path="/investor/what-is-mic" component={InvestorWhatIsMIC} />
        <Route path="/investor-ch/what-is-mic" component={InvestorWhatIsMICCH} />
        <Route path="/investor/investment-strategy" component={InvestorInvestmentStrategy} />
        <Route path="/investor-ch/investment-strategy" component={InvestorInvestmentStrategyCH} />

        <Route path="/investor/news-and-events" component={InvestorNewsAndEvents} />
        <Route path="/investor-ch/news-and-events" component={InvestorNewsAndEventsCH} />
        <Route path="/investor/events" component={InvestorEventsAndSeminars} />
        <Route path="/investor-ch/events" component={InvestorEventsAndSeminarsCH} />
        <Route path="/investor/gallery" component={InvestorGallery} />
        <Route path="/investor-ch/gallery" component={InvestorGalleryCH} />

        <Route path="/investor/analyst-rating" component={InvestorAnalystRating} />
        <Route path="/investor-ch/analyst-rating" component={InvestorAnalystRatingCH} />
        <Route path="/investor/lending-map" component={InvestorLendingMap} />
        <Route path="/investor-ch/lending-map" component={InvestorLendingMapCH} />
        <Route path="/investor/historical-return" component={InvestorHistoricalReturn} />
        <Route path="/investor-ch/historical-return" component={InvestorHistoricalReturnCH} />
        <Route path="/investor/loan-portfolio" component={InvestorLoanPortfolio} />
        <Route path="/investor-ch/loan-portfolio" component={InvestorLoanPortfolioCH} />
        <Route path="/investor/fund-portfolio" component={InvestorFundPortfolio} />
        <Route path="/investor-ch/fund-portfolio" component={InvestorFundPortfolioCH} />
        <Route path="/investor/our-performance" component={InvestorOurPerformance} />
        <Route path="/investor-ch/our-performance" component={InvestorOurPerformanceCH} />

        <Route path="/investor/about-us" component={InvestorAboutUs} />
        <Route path="/investor-ch/about-us" component={InvestorAboutUsCH} />
        <Route path="/investor/our-story" component={InvestorOurStory} />
        <Route path="/investor-ch/our-story" component={InvestorOurStoryCH} />
        <Route path="/investor/team" component={InvestorTeam} />
        <Route path="/investor-ch/team" component={InvestorTeamCH} />
        <Route path="/investor/why-us" component={InvestorWhyUs} />
        <Route path="/investor-ch/why-us" component={InvestorWhyUsCH} />
        <Footer />
    </BrowserRouter>
);


export default Router;
