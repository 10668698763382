import React from "react";

import image1 from "./image1.png";
import image2 from "./image2.png";
import image3 from "./image3.png";
import image4 from "./image4.png";
import image5 from "./image5.png";
export const topCarouselCards = [
    {
        title: <span>为什么投资MIC的风险相对较低？ </span>,
        text: (
            <span>
                投资房贷投资基金股份意味着您投资于一个管理多元化且有保障的房贷资产池的公司。<br></br>分散风险：通过投资多项物业来分散风险。而非单一房地产投资，这通常需要更高的资本投入。<br></br>有形资产保障：房贷投资基金股份以实际物业为抵押，价值相对稳健；而股票等纸质资产则没有内在价值。
            </span>
        ),
        buttonTitle: <span>查看我们投资业绩表现，了解更多！</span>,
        image: image5,
        link: "/investor-ch/our-performance"
    },
    {
        title: <span>投资者如何赚钱？</span>,
        text: (
            <span>
                在当前市场中：<br></br>一按（首位按揭）利率：通常为3%-5%。<br></br>二按（第二按揭）利率：通常为10%-16%。<br></br>Ginkgo盈信专注于高利率的二按项目，为我们的投资者提供更高的回报机会。
                <br />
                <br /> 作为一种传递型工具，MIC产生的利润需按照股东的比例分配，确保公平与透明。
            </span>
        ),
        buttonTitle: <span>查看我们投资业绩表现，了解更多！</span>,
        image: image4,
        link: "/investor-ch/our-performance"
    },

    {
        title: <span>Ginkgo盈信如何盈利？</span>,
        text: (
            <span>
                我们的业务模式与银行类似，通过按揭贷款向借款人发放资金，并收取月利息来支付投资者。然而，Ginkgo盈信与银行的不同之处在于：<br></br>我们主要提供第二按揭贷款，可以收取更高的利率，为投资者带来更高的分红。<br></br>我们的按揭贷款利率平均为10%-16%，投资者可享受约8%-9%的稳健分红收益。
            </span>
        ),
        buttonTitle: <span>查看我们投资业绩表现，了解更多！</span>,
        image: image3,
        link: "/investor-ch/our-performance"
    },
    {
        title: <span>谁会向盈信房贷基金借款？</span>,
        text: (
            <span>
                我们的目标是帮助解决财务问题 <br />
                <br /> 我们的按揭贷款产品旨在为借款人提供短期资金支持，帮助他们改善信用，直到能够再次符合传统金融机构的贷款资格。
            </span>
        ),
        image: image2,
        buttonTitle: <span>查看我们投资业绩表现，了解更多！</span>,
        link: "/investor-ch/our-performance"
    },
    {
        title: <span>MIC的保障机制是什么？</span>,
        text: (
            <span>
                Ginkgo 盈信主要投资于住宅物业的一按贷款。每一笔投资都基于全面的物业调查，包括专业的评估报告。通常，贷款金额不超过物业现值的80%，以尽力确保在房价下跌或其他经济不利因素下，投资本金不受影响。
            </span>
        ),
        image: image1,
        buttonTitle: <span>查看我们投资业绩表现，了解更多！</span>,
        link: "/investor-ch/our-performance"
    }
];

export const bulletPoints = [
    "获得FSCO许可",
    "让您的收入免税增长！",
    "根据《所得税法》合格",
    "符合RDSP、RRSP、RRIF、TFSA、RESP资格",
    "在提取之前，享受免税增长和再投资。"
];
