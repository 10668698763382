import React, { PureComponent } from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import "./BodySection.css";
import { HistorialReturnsData } from "..";
import axios from 'axios'
import { serURL } from '../../../../../../API/url'


class BodySection extends React.Component {  
    constructor(props) {  
        super(props)
          this.state = {
              returnS1 : [],
              returnS2 : []
            }
      }


readSPReturn = async() => {
  
    const getHisRetS1 = await axios.get(serURL+"/hisreturnS1"
       ).then((response) => response
       )
       .catch((err) => console.log(err))
     this.setState({returnS1: getHisRetS1.data.reverse()})
  //   console.log(this.state.returnS1)
    const getHisRetS2 = await axios.get(serURL+"/hisreturnS2"
       ).then((response) => response
       )
       .catch((err) => console.log(err))
     this.setState({returnS2: getHisRetS2.data.reverse()})
  //  console.log(this.state.returnS2)
 }

componentDidMount() {
   this.readSPReturn()
 //   this.state.returns = this.state.returns.data.slice(17,18)
}

render() {

    return (
// const BodySection = props => (
    <div className="pagePadding-Investor paddingTopXL BodySection">
        <div className="paddingLeftXL paddingRightXL centerText marginOnMobile">
            <H1 className="fontSizeLL">回报历史</H1>
            <div className="FirstSectionInnerContnet paddingTopM fontWeightNormal plainText fontSizeM lineHeightM fadeInUp">
            Ginkgo盈信为投资者提供稳健的月收入。此外，您的投资基础资产价值不会像股票和债券那样波动。稳健的回报非常适合退休计划，并提供舒适。它还提供了以较低利率借贷并通过“利差”（借贷成本和投资收益之间的差额）赚钱的机会。
            </div>
            <div className="paddingTopL paddingBottomXL">
                <HistorialReturnsData historylistS1={this.state.returnS1} historylistS2={this.state.returnS2}/>
            </div>
        </div>
    </div>
    );
};
};
export default BodySection;
