import React from "react";
import { pure } from "recompose";
import { H1, H2 } from "../../../../../../Atoms";
import "./BodySection.css";
import Grid from "@material-ui/core/Grid";

const BodySection = props => {
    return (
        <div className="pagePadding-Investor paddingTopL paddingBottomXL BodySection">
            <Grid className="" justify="center" container direction="row">
                <Grid item>
                    <H2 className="fontSize28 headerSection">最新内容</H2>
                    <a href="/investor-ch/what-is-mic">
                        <div className="whatIsMicSection withBackground">
                            <div className="lightText paddingLeftL bottomInnerSection paddingBottomL paddingRightXL">
                                <H1 className="fontSizeLL">什么是MIC？</H1>
                                <div className="greenLine primaryBackground"></div>
                                <div className="fontSize26 fontWeight300fadeInUp paddingTopM">
                                    抵押贷款投资公司能为投资者做些什么？让我们为您展示。
                                </div>
                            </div>
                        </div>
                    </a>
                </Grid>
                <Grid item>
                    <div className="zeroLeftPaddingMobile paddingLeftML">
                        <a href="/investor-ch/investment-strategy">
                            <div className="headerSection subHeadersection fontWeightNormal fontSizeS lineHeightM rightText">
                                <div className="paddingTopS paddingRightM hiddenOnMobile">查看全部</div>
                            </div>
                            <div className="paddingBottomML">
                                <div className="investmentStrategiesSection smallerColumnSection withBackground">
                                    <div className="lightText paddingLeftS bottomInnerSection paddingBottomS">
                                        <div className="shortGreenLine primaryBackground"></div>
                                        <H2 className="">投资策略</H2>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="/investor-ch/how-to-invest">
                            <div className="investWithGinkgoSection smallerColumnSection withBackground">
                                <div className="lightText paddingLeftS bottomInnerSection paddingBottomS">
                                    <div className="shortGreenLine primaryBackground"></div>
                                    <H2 className="">如何与Ginkgo投资</H2>
                                </div>
                            </div>
                        </a>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default pure(BodySection);
