import React, { PureComponent } from 'react'
import { H2 } from '../../../../Atoms'
import { OnScrollAnimatior } from '../../../../Molecules'
import { pure } from 'recompose'
import agentIcon from './agent-icon.svg'
import fundsIcon from './funds-icon.svg'
import investedIcon from './invested-icon.svg'
import loansIcon from './loan-icon.svg'
import axios from 'axios'
import { serURL } from '../../../../API/url'
import './Facts.css'

class Facts extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      Funded: '1',
      Capital: '82',
      Agent: '730+',
      Loan: '353'
    }
  }

  readSPFacts = async () => {

    // const getFacts = await axios.get(serURL + "/facts")
    //   .then((response) => response)
    //   .catch((err) => console.log(err));
    // this.setState({ Funded: getFacts.data.Title, Capital: getFacts.data.field_1, Agent: getFacts.data.field_2, Loan: getFacts.data.field_3 })
    await axios.get(serURL + "/facts")
      .then((resp) => {
        this.setState({
          Funded: resp.data.Title,
          Capital: resp.data.field_1,
          Agent: resp.data.field_2,
          Loan: resp.data.field_3
        })
      })
      .catch((err) => {
        // console.log(err)
      });
  }

  componentDidMount() {
    this.readSPFacts()
  }

  render() {
    //const Facts = props => {
    const isMobile = window.innerWidth < 800
    return (
      <div className='Facts darkBackground lightText'>
        <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
          <H2>
            The numbers speak for themselves
          </H2>
          <div className='plainText paddingTopM fontSizeXS'>
            We have been lending across Canada since 2011, and have garnered a reputation for quality service, efficient management and strong returns.
          </div>

        </OnScrollAnimatior>
        <div className='factsRow row paddingTopM'>
          <div className='singleFactContainer row'>
            <OnScrollAnimatior animation='pulse'>
              <img src={fundsIcon} alt='' />
            </OnScrollAnimatior>
            <div className='textWrap paddingLeftS'>
              <OnScrollAnimatior animation='tada'>
                <b className='fontSizeL '>
                  ${this.state.Funded}
                </b>
              </OnScrollAnimatior>
              <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                <div className='fontSizeXS'>
                  Billion funded
                </div>
              </OnScrollAnimatior>
            </div>
          </div>
          <div className='singleFactContainer row'>
            <OnScrollAnimatior animation='pulse'>
              <img src={investedIcon} alt='' />
            </OnScrollAnimatior>

            <div className='textWrap paddingLeftS'>
              <OnScrollAnimatior animation='tada'>
                <b className='fontSizeL'>
                  ${this.state.Capital}
                </b>
              </OnScrollAnimatior>
              <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                <div className='fontSizeXS'>
                  Million Porfolio Size

                </div>
              </OnScrollAnimatior>

            </div>
          </div>
          <div className='singleFactContainer row'>
            <OnScrollAnimatior animation='pulse'>
              <img src={agentIcon} alt='' />
            </OnScrollAnimatior>

            <div className='textWrap paddingLeftS'>
              <OnScrollAnimatior animation='tada'>
                <b className='fontSizeL'>
                  {this.state.Agent}
                </b>
              </OnScrollAnimatior>
              <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                <div className='fontSizeXS'>
                  Agents Assisted
                </div>
              </OnScrollAnimatior>

            </div>
          </div>
          <div className='singleFactContainer row'>
            <OnScrollAnimatior animation='pulse'>
              <img src={loansIcon} alt='' />
            </OnScrollAnimatior>

            <div className='textWrap paddingLeftS'>
              <OnScrollAnimatior animation='tada'>
                <b className='fontSizeL'>
                  {this.state.Loan}
                </b>
              </OnScrollAnimatior>
              <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                <div className='fontSizeXS'>
                  Active Loans
                </div>
              </OnScrollAnimatior>

            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default pure(Facts)