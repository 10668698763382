import React from "react";
import { pure } from "recompose";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(25),
        fontWeight: theme.typography.fontWeightBold
    }
}));

const HistoryReturnRow = ({
    hisReturn: {
        Title: Year,
        field_1: Month,
        field_2: SpecialDividend,
        field_3: DividendRate,
        field_4: AnnualizedReturn,
        field_5: SinceInception,
        field_6: Unit
    }
}) => (
    <tr ng-repeat="z in y" class="ng-scope">
        <td class="ng-binding">{Year}</td>
        <td class="ng-binding">{Month}</td>
        <td class="ng-binding">{SpecialDividend}</td>
        <td class="ng-binding">{DividendRate}</td>
        <td align="ng-binding">{AnnualizedReturn}</td>
        <td class="ng-binding">{SinceInception}</td>
        <td class="ng-binding">{Unit}</td>
    </tr>
);

const HistorialReturnsData = ({ historylistS1,  historylistS2 }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState("2025");
    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
   // console.log(expanded)
  return (
        <div class="container">
            <div>                
            <ExpansionPanel expanded={expanded === "2025"} onChange={handleChange("2025")}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>2025</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>

                        <div style={{display:'flex',flexDirection:'column',width: '100%'}}>   

                        <div className="responsive-table">
                            <h2 className="text-center">Series 1</h2>
                            <table className="table table-striped table-condensed table-active table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Special Dividend</td>
                                        <td>Annualized Return</td>
                                        <td>Since Inception</td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {  historylistS1.filter(hisReturn => hisReturn.Title==='2025').map((hisReturn) => <HistoryReturnRow hisReturn={hisReturn}/>) }{" "}
                                </tbody>
                            </table>
                        </div>

                        <div className="responsive-table">
                            <h2 className="text-center">Series 2</h2>
                            <table className="table table-striped table-condensed table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Special Dividend</td>
                                        <td>Annualized Return</td>
                                        <td>DRIP Program   </td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {  historylistS2.filter(hisReturn => hisReturn.Title==='2025').map((hisReturn) => <HistoryReturnRow hisReturn={hisReturn}/>) }{" "}
                                </tbody>
                            </table>
                        </div>

                        </div>   
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                
                <ExpansionPanel expanded={expanded === "2024"} onChange={handleChange("2024")}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>2024</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>

                        <div style={{display:'flex',flexDirection:'column',width: '100%'}}>   

                        <div className="responsive-table">
                            <h2 className="text-center">Series 1</h2>
                            <table className="table table-striped table-condensed table-active table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Special Dividend</td>
                                        <td>Annualized Return</td>
                                        <td>Since Inception</td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {  historylistS1.filter(hisReturn => hisReturn.Title==='2024').map((hisReturn) => <HistoryReturnRow hisReturn={hisReturn}/>) }{" "}
                                </tbody>
                            </table>
                        </div>

                        <div className="responsive-table">
                            <h2 className="text-center">Series 2</h2>
                            <table className="table table-striped table-condensed table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Special Dividend</td>
                                        <td>Annualized Return</td>
                                        <td>DRIP Program   </td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {  historylistS2.filter(hisReturn => hisReturn.Title==='2024').map((hisReturn) => <HistoryReturnRow hisReturn={hisReturn}/>) }{" "}
                                </tbody>
                            </table>
                        </div>

                        </div>   
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                
                <ExpansionPanel expanded={expanded === "2023"} onChange={handleChange("2023")}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>2023</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>

                        <div style={{display:'flex',flexDirection:'column',width: '100%'}}>   

                        <div className="responsive-table">
                            <h2 className="text-center">Series 1</h2>
                            <table className="table table-striped table-condensed table-active table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Special Dividend</td>
                                        <td>Annualized Return</td>
                                        <td>Since Inception</td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {  historylistS1.filter(hisReturn => hisReturn.Title==='2023').map((hisReturn) => <HistoryReturnRow hisReturn={hisReturn}/>) }{" "}
                                </tbody>
                            </table>
                        </div>

                        <div className="responsive-table">
                            <h2 className="text-center">Series 2</h2>
                            <table className="table table-striped table-condensed table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Special Dividend</td>
                                        <td>Annualized Return</td>
                                        <td>DRIP Program   </td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {  historylistS2.filter(hisReturn => hisReturn.Title==='2023').map((hisReturn) => <HistoryReturnRow hisReturn={hisReturn}/>) }{" "}
                                </tbody>
                            </table>
                        </div>

                        </div>   
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                

            <ExpansionPanel expanded={expanded === "2022"} onChange={handleChange("2022")}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>2022</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>

                        <div style={{display:'flex',flexDirection:'column',width: '100%'}}>   

                        <div className="responsive-table">
                            <h2 className="text-center">Series 1</h2>
                            <table className="table table-striped table-condensed table-active table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Special Dividend</td>
                                        <td>Annualized Return</td>
                                        <td>Since Inception</td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {  historylistS1.filter(hisReturn => hisReturn.Title==='2022').map((hisReturn) => <HistoryReturnRow hisReturn={hisReturn}/>) }{" "}
                                </tbody>
                            </table>
                        </div>

                        <div className="responsive-table">
                            <h2 className="text-center">Series 2</h2>
                            <table className="table table-striped table-condensed table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Special Dividend</td>
                                        <td>Annualized Return</td>
                                        <td>DRIP Program   </td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {  historylistS2.filter(hisReturn => hisReturn.Title==='2022').map((hisReturn) => <HistoryReturnRow hisReturn={hisReturn}/>) }{" "}
                                </tbody>
                            </table>
                        </div>

                        </div>   
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                
                <ExpansionPanel expanded={expanded === "2021"} onChange={handleChange("2021")}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>2021</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>

                        <div style={{display:'flex',flexDirection:'column',width: '100%'}}>

                        <div className="responsive-table">
                            <h2 className="text-center">Series 1</h2>
                            <table className="table table-striped table-condensed table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Special Dividend</td>
                                        <td>Annualized Return</td>
                                        <td>Since Inception</td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Dec</td>
                                        <td class="ng-binding">0.67%</td>
                                        <td class="ng-binding"></td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.55%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Nov</td>
                                        <td class="ng-binding">0.67%</td>
                                        <td class="ng-binding"></td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.55%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Oct</td>
                                        <td class="ng-binding">0.67%</td>
                                        <td class="ng-binding"></td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.55%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Sep</td>
                                        <td class="ng-binding">0.67%</td>
                                        <td class="ng-binding">0.20%</td>
                                        <td class="ng-binding">8.20%</td>
                                        <td class="ng-binding">8.56%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Aug</td>
                                        <td class="ng-binding">0.67%</td>
                                        <td class="ng-binding"></td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.57%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Jul</td>
                                        <td class="ng-binding">0.67%</td>
                                        <td class="ng-binding"></td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.57%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Jun</td>
                                        <td class="ng-binding">0.67%</td>
                                        <td class="ng-binding"></td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.58%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">May</td>
                                        <td class="ng-binding">0.67%</td>
                                        <td class="ng-binding"></td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.58%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Apr</td>
                                        <td class="ng-binding">0.67%</td>
                                        <td class="ng-binding"></td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.59%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Mar</td>
                                        <td class="ng-binding">0.67%</td>
                                        <td class="ng-binding"></td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.59%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Feb</td>
                                        <td class="ng-binding">0.67%</td>
                                        <td class="ng-binding"></td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.60%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Jan</td>
                                        <td class="ng-binding">0.67%</td>
                                        <td class="ng-binding"></td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.61%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="responsive-table">
                            <h2 className="text-center">Series 2</h2>
                            <table className="table table-striped table-condensed table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Annualized Return</td>
                                        <td>DRIP Program   </td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                <   tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Dec</td>
                                        <td class="ng-binding">0.58%</td>
                                        <td class="ng-binding">7.00%</td>
                                        <td class="ng-binding">7.23%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                <   tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Nov</td>
                                        <td class="ng-binding">0.58%</td>
                                        <td class="ng-binding">7.00%</td>
                                        <td class="ng-binding">7.23%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Oct</td>
                                        <td class="ng-binding">0.58%</td>
                                        <td class="ng-binding">7.00%</td>
                                        <td class="ng-binding">7.23%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Sep</td>
                                        <td class="ng-binding">0.58%</td>
                                        <td class="ng-binding">7.00%</td>
                                        <td class="ng-binding">7.23%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Aug</td>
                                        <td class="ng-binding">0.58%</td>
                                        <td class="ng-binding">7.00%</td>
                                        <td class="ng-binding">7.23%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Jul</td>
                                        <td class="ng-binding">0.58%</td>
                                        <td class="ng-binding">7.00%</td>
                                        <td class="ng-binding">7.23%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Jun</td>
                                        <td class="ng-binding">0.58%</td>
                                        <td class="ng-binding">7.00%</td>
                                        <td class="ng-binding">7.23%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">May</td>
                                        <td class="ng-binding">0.58%</td>
                                        <td class="ng-binding">7.00%</td>
                                        <td class="ng-binding">7.23%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Apr</td>
                                        <td class="ng-binding">0.58%</td>
                                        <td class="ng-binding">7.00%</td>
                                        <td class="ng-binding">7.23%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Mar</td>
                                        <td class="ng-binding">0.58%</td>
                                        <td class="ng-binding">7.00%</td>
                                        <td class="ng-binding">7.23%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Feb</td>
                                        <td class="ng-binding">0.58%</td>
                                        <td class="ng-binding">7.00%</td>
                                        <td class="ng-binding">7.23%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2021</td>
                                        <td class="ng-binding">Jan</td>
                                        <td class="ng-binding">0.58%</td>
                                        <td class="ng-binding">7.00%</td>
                                        <td class="ng-binding">7.23%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === "2020"} onChange={handleChange("2020")}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>2020</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div className="responsive-table">
                            <table className="table table-striped table-condensed table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Annualized Return</td>
                                        <td>Overall Return</td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2020</td>
                                        <td class="ng-binding">Dec</td>
                                        <td class="ng-binding">0.667%</td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.61%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2020</td>
                                        <td class="ng-binding">Nov</td>
                                        <td class="ng-binding">0.667%</td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.62%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2020</td>
                                        <td class="ng-binding">Oct</td>
                                        <td class="ng-binding">0.667%</td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.62%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2020</td>
                                        <td class="ng-binding">Sep</td>
                                        <td class="ng-binding">0.667%</td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.63%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2020</td>
                                        <td class="ng-binding">Aug</td>
                                        <td class="ng-binding">0.667%</td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.64%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2020</td>
                                        <td class="ng-binding">Jul</td>
                                        <td class="ng-binding">0.667%</td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.64%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2020</td>
                                        <td class="ng-binding">Jun</td>
                                        <td class="ng-binding">0.667%</td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.65%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2020</td>
                                        <td class="ng-binding">May</td>
                                        <td class="ng-binding">0.667%</td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.66%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2020</td>
                                        <td class="ng-binding">Apr</td>
                                        <td class="ng-binding">0.667%</td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.66%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2020</td>
                                        <td class="ng-binding">Mar</td>
                                        <td class="ng-binding">0.667%</td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.67%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2020</td>
                                        <td class="ng-binding">Feb</td>
                                        <td class="ng-binding">0.667%</td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.68%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2020</td>
                                        <td class="ng-binding">Jan</td>
                                        <td class="ng-binding">0.667%</td>
                                        <td class="ng-binding">8.00%</td>
                                        <td class="ng-binding">8.69%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                </tbody>
                            </table>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === "2019"} onChange={handleChange("2019")}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>2019</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div className="responsive-table">
                            <table className="table table-striped table-condensed table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Annualized Return</td>
                                        <td>Overall Return</td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2019</td>
                                        <td class="ng-binding">Dec</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.69%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2019</td>
                                        <td class="ng-binding">Nov</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.69%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2019</td>
                                        <td class="ng-binding">Oct</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.69%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2019</td>
                                        <td class="ng-binding">Sept</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.70%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2019</td>
                                        <td class="ng-binding">Aug</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.70%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2019</td>
                                        <td class="ng-binding">Jul</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.70%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2019</td>
                                        <td class="ng-binding">Jun</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.70%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2019</td>
                                        <td class="ng-binding">May</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.70%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2019</td>
                                        <td class="ng-binding">Apr</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.70%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2019</td>
                                        <td class="ng-binding">Mar</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.71%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2019</td>
                                        <td class="ng-binding">Feb</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.71%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2019</td>
                                        <td class="ng-binding">Jan</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.72%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === "2018"} onChange={handleChange("2018")}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>2018</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div className="responsive-table">
                            <table class="table table-striped table-condensed table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Annualized Return</td>
                                        <td>Overall Return</td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2018</td>
                                        <td class="ng-binding">Dec</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.72%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2018</td>
                                        <td class="ng-binding">Nov</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.72%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2018</td>
                                        <td class="ng-binding">Oct</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.73%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2018</td>
                                        <td class="ng-binding">Sep</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.73%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2018</td>
                                        <td class="ng-binding">Aug</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.73%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2018</td>
                                        <td class="ng-binding">JuL</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.74%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2018</td>
                                        <td class="ng-binding">Jun</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.74%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2018</td>
                                        <td class="ng-binding">May</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.74%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2018</td>
                                        <td class="ng-binding">Apr</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.75%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2018</td>
                                        <td class="ng-binding">Mar</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.75%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2018</td>
                                        <td class="ng-binding">Feb</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.75%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2018</td>
                                        <td class="ng-binding">Jan</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.76%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>{" "}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === "2017"} onChange={handleChange("2017")}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>2017</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div className="responsive-table">
                            <table class="table table-striped table-condensed table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Annualized Return</td>
                                        <td>Overall Return</td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2017</td>
                                        <td class="ng-binding">Dec</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.76%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2017</td>
                                        <td class="ng-binding">Nov</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.76%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2017</td>
                                        <td class="ng-binding">Oct</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.76%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2017</td>
                                        <td class="ng-binding">Sep</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.77%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2017</td>
                                        <td class="ng-binding">Aug</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.77%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2017</td>
                                        <td class="ng-binding">Jul</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.78%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2017</td>
                                        <td class="ng-binding">Jun</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.79%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2017</td>
                                        <td class="ng-binding">May</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.79%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2017</td>
                                        <td class="ng-binding">Apr</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.80%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2017</td>
                                        <td class="ng-binding">Mar</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.81%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2017</td>
                                        <td class="ng-binding">Feb</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.81%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2017</td>
                                        <td class="ng-binding">Jan</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.82%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>{" "}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === "2016"} onChange={handleChange("2016")}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>2016</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div className="responsive-table">
                            <table class="table table-striped table-condensed table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Annualized Return</td>
                                        <td>Overall Return</td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2016</td>
                                        <td class="ng-binding">Dec</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.83%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2016</td>
                                        <td class="ng-binding">Nov</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.84%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2016</td>
                                        <td class="ng-binding">Oct</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.84%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2016</td>
                                        <td class="ng-binding">Sep</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.84%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2016</td>
                                        <td class="ng-binding">Aug</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.85%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2016</td>
                                        <td class="ng-binding">Jul</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.86%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2016</td>
                                        <td class="ng-binding">Jun</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.87%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2016</td>
                                        <td class="ng-binding">May</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.88%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2016</td>
                                        <td class="ng-binding">Apr</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.89%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2016</td>
                                        <td class="ng-binding">Mar</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.90%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2016</td>
                                        <td class="ng-binding">Feb</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.90%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2016</td>
                                        <td class="ng-binding">Jan</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.91%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === "2015"} onChange={handleChange("2015")}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>2015</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div className="responsive-table">
                            {" "}
                            <table class="table table-striped table-condensed table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Annualized Return</td>
                                        <td>Overall Return</td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2015</td>
                                        <td class="ng-binding">Dec</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.92%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2015</td>
                                        <td class="ng-binding">Nov</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.93%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2015</td>
                                        <td class="ng-binding">Oct</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.95%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2015</td>
                                        <td class="ng-binding">Sep</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.96%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2015</td>
                                        <td class="ng-binding">Aug</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">8.96%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2015</td>
                                        <td class="ng-binding">Jul</td>
                                        <td class="ng-binding">0.708%</td>
                                        <td class="ng-binding">8.50%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2015</td>
                                        <td class="ng-binding">Jun</td>
                                        <td class="ng-binding">0.75%</td>
                                        <td class="ng-binding">9.0%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2015</td>
                                        <td class="ng-binding">May</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2015</td>
                                        <td class="ng-binding">Apr</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2015</td>
                                        <td class="ng-binding">Mar</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2015</td>
                                        <td class="ng-binding">Feb</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2015</td>
                                        <td class="ng-binding">Jan</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === "2014"} onChange={handleChange("2014")}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>2014</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div className="responsive-table">
                            {" "}
                            <table className="table table-striped table-condensed table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Annualized Return</td>
                                        <td>Overall Return</td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2014</td>
                                        <td class="ng-binding">Dec</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2014</td>
                                        <td class="ng-binding">Nov</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2014</td>
                                        <td class="ng-binding">Oct</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2014</td>
                                        <td class="ng-binding">Sep</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2014</td>
                                        <td class="ng-binding">Aug</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2014</td>
                                        <td class="ng-binding">Jul</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2014</td>
                                        <td class="ng-binding">Jun</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2014</td>
                                        <td class="ng-binding">May</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2014</td>
                                        <td class="ng-binding">Apr</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2014</td>
                                        <td class="ng-binding">Mar</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2014</td>
                                        <td class="ng-binding">Feb</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2014</td>
                                        <td class="ng-binding">Jan</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === "2013"} onChange={handleChange("2013")}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>2013</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div className="responsive-table">
                            {" "}
                            <table class="table table-striped table-condensed table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Annualized Return</td>
                                        <td>Overall Return</td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2013</td>
                                        <td class="ng-binding">Dec</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2013</td>
                                        <td class="ng-binding">Nov</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2013</td>
                                        <td class="ng-binding">Oct</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2013</td>
                                        <td class="ng-binding">Sep</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2013</td>
                                        <td class="ng-binding">Aug</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2013</td>
                                        <td class="ng-binding">Jul</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2013</td>
                                        <td class="ng-binding">Jun</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2013</td>
                                        <td class="ng-binding">May</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2013</td>
                                        <td class="ng-binding">Apr</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2013</td>
                                        <td class="ng-binding">Mar</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2013</td>
                                        <td class="ng-binding">Feb</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2013</td>
                                        <td class="ng-binding">Jan</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === "2012"} onChange={handleChange("2012")}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>2012</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div className="responsive-table">
                            {" "}
                            <table class="table table-striped table-condensed table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Annualized Return</td>
                                        <td>Overall Return</td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2012</td>
                                        <td class="ng-binding">Dec</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2012</td>
                                        <td class="ng-binding">Nov</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2012</td>
                                        <td class="ng-binding">Oct</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2012</td>
                                        <td class="ng-binding">Sep</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2012</td>
                                        <td class="ng-binding">Aug</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2012</td>
                                        <td class="ng-binding">Jul</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2012</td>
                                        <td class="ng-binding">Jun</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2012</td>
                                        <td class="ng-binding">May</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2012</td>
                                        <td class="ng-binding">Apr</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2012</td>
                                        <td class="ng-binding">Mar</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2012</td>
                                        <td class="ng-binding">Feb</td>
                                        <td class="ng-binding">0.750%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2012</td>
                                        <td class="ng-binding">Jan</td>
                                        <td class="ng-binding">0.758%</td>
                                        <td class="ng-binding">9.10%</td>
                                        <td class="ng-binding">9.00%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === "2011"} onChange={handleChange("2011")}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>2011</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div className="responsive-table">
                            <table className="table table-striped table-condensed table-responsive">
                                <thead>
                                    <tr>
                                        <td>Year</td>
                                        <td>Month</td>
                                        <td>Dividend Rate</td>
                                        <td>Annualized Return</td>
                                        <td>Overall Return</td>
                                        <td>Unit Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2011</td>
                                        <td class="ng-binding">Dec</td>
                                        <td class="ng-binding">0.696%</td>
                                        <td class="ng-binding">8.35%</td>
                                        <td class="ng-binding">8.96%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>{" "}
                                    <tr ng-repeat="z in y" class="ng-scope">
                                        <td class="ng-binding">2011</td>
                                        <td class="ng-binding">Nov</td>
                                        <td class="ng-binding">0.797%</td>
                                        <td class="ng-binding">9.56%</td>
                                        <td class="ng-binding">9.56%</td>
                                        <td class="ng-binding">$10</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
       </div>  
    );
};

export default HistorialReturnsData;
