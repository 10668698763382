import React from 'react'
import { pure } from 'recompose'
import { QuestionAndAnswer, OnScrollAnimatior } from '../../../../../../Molecules'
import { H2, Button } from '../../../../../../Atoms'
import { Link, withRouter } from 'react-router-dom'
import './Body.css'

const questionsAndAnswers = [
/*      {
        question: 'Can I redeem my investment during the COVID-19 lock-down period?',
        answer: 
        <div>
            <p>
                At this time Ginkgo will not be serving any redemptions until June 30, 2020. We will be doing our part in social consciousness during this time by providing a 3-month mortgage payment deferral program to the borrowers, and this measure will allow us to provide sufficient operations during and beyond this time of temporary uncertainty.Rest assured that we have sufficient provisions to do so without impacting your investment, and your monthly interest income distribution will be continued during these times.
            </p>
        </div>
    },
     {
        question: 'Are there any restrictions on redemption?',
        answer: 
        <div>
            <p>
                Investors will not be able to redeem their investment from March 31 to June 30th,2020, or until further notice. After this period, investors will be able to redeem a maximum 5% of total investment not more than $25,000 each month.
            </p>
        </div>
    },
    {
        question: 'Will investors still receive dividends?',
        answer: 
        <div>
            <p>
                Yes, investors will continue to receive their monthly dividends during this period.    
            </p>
        </div>
    },
    {
        question: 'Will the dividend rate will be reduced?',
        answer: 
        <div>
            <p>
                We do not foresee a rate reduction in the immediate future, but it will be consideredif necessary. As the COVID-19 pandemic is an unprecedented phenomenon, it is difficult to predict future measures. We are constantly monitoring the overall economic and mortgage landscape, and will make appropriate adjustments to ensure the longevity of your investment.    
            </p>
        </div>
    },
      {
        question: 'If I would like to have a safer investment, are there any options?',
        answer: 
        <div>
            <p>
                Ginkgo MIC investments are secured by real estate, which are one of the most enduring assets throughout history. Investors currently holding Preferred Shares cantransfer to our Bond products, which will receive a fixed rate of 5% (1 year term - Bond A) or 6% return (2 year term - Bond B). The principle balance is secured by Ginkgo MIC’s assets through a General Securities Agreement (GSA), and bond holders will be paid first in the case of liquidation, meaning bonds have a lower risk than preferred shares.&nbsp;
                <Link to="/investor-ch/investment-products" className={"link"}>
                        See our investment products
                </Link>
            </p>
        </div>
    },
*/    {
        question: '我可以联系谁了解我的投资情况？',
        answer: 
        <div>
            <p>
                您可以拨打我们的投资者热线 (647) 660-2293 联系我们。<a className="underline fontWeightBold" href="mailto:investor@ginkgomic.com">investor@ginkgomic.com</a>或者联系Yvonne Leung，电话：(416) 990-5567。<a className="underline fontWeightBold" href="mailto:yvonne@ginkgomic.com">yvonne@ginkgomic.com</a>
            </p>
        </div>
    },
    {
        question: '我可以投资的最低金额是多少？',
        answer: 
        <div>
            <p>
                最低投资金额为$10,000，投资者需要提供合格投资者证书（Eligible Investor Certificate）。选择投资超过$100,000的投资者需要提供认可投资者证书（Accredited Investor Certificate）。这两种证书都可以通过您的豁免市场经销商（EMD）完成。您也可以通过拨打我们的电话 +1 (855) 901-5133 安排与EMD的会面。
            </p>
        </div>
    },
    {
        question: '股息何时发放？',
        answer: 
        <div>
            <p>
                股息于每月15日发放。您将收到一条短信，通知您存入Ginkgo账户的金额。同时，您还会收到一封电子邮件，了解您投资的整体表现。
            </p>
        </div>
    },
    {
        question: '我如何知道我的投资表现良好？',
        answer: 
        <div>
            <p>
                就像银行一样，我们每月会通过电子邮件向您发送一份报告，告知您的投资表现和状况。
            </p>
            <p>
                您还将受邀参加我们的年度股东大会，以更好地了解公司的整体表现。这也是一个与管理您投资的工作人员见面的好机会，同时获取未来经济展望的相关信息。因此，提供最新的联系方式非常重要，以便我们及时通知您有关投资的最新动态。您的个人信息将被严格保密，仅用于公司日常运营，不会出售给第三方。
            </p>
        </div>
    },
/*     {
        question: 'I heard about Ponzi schemes. How do I know Ginkgo MIC is not one of those?',
        answer: 
        <div>
            <p>
                This is a good question. A Ponzi scheme does not have a real business. It is a fraudulent investment operation that pays returns to its investors from the investor's own money or the money paid by subsequent investors, rather than from profit earned by the company's operation. The Ponzi scheme usually entices new investors by offering higher returns than other investments, in the form of short-term returns that are either abnormally high or unusually consistent. Perpetuation of the high returns requires an ever-increasing flow of money from new investors to keep the scheme going.     
            </p>
        </div>
    },
 */    {
        question: '第一抵押贷款与第二抵押贷款有什么区别？',
        answer: 
        <div>
            <p>
                第一抵押贷款与第二抵押贷款的主要区别在于债务的优先级。
            </p>
            <p>
                如果发生违约，第一抵押贷款人和第二抵押贷款人都有权对财产进行止赎并在市场上出售以偿还债务。然而，第一抵押贷款人优先获得出售收益；第二抵押贷款人则有权获得剩余的金额。由于第二抵押贷款排在第一抵押贷款之后，因此风险更高，因此通常要求更高的回报。
            </p>
        </div>
    },
    {
        question: 'Ginkgo如何投资您的资金？',
        answer: 
        <div>
            <p>
                Ginkgo将您的资金投资于一个多元化的抵押贷款组合，投资者的收益来自借款人支付的利息。
            </p>
        </div>
    },
    {
        question: '我的投资预期回报率是多少？',
        answer: 
        <div>
            <p>
                目前预期的回报率为8-9%。
            </p>
        </div>
    },
    {
        question: '赎回我的投资的流程是什么？',
        answer: 
        <div>
            <p>
                股东可以每月请求赎回任何或全部未偿还的股份，但需提前30天通知我们。我们的股份在认购日起的前两年内需支付提前终止费用。两年后请求赎回的股份将不收取任何提前赎回费用。股份在认购后的前六个月内不能赎回。
            </p>
        </div>
    },
    {
        question: '如何设置注册账户？',
        answer: 
        <div>
            <p>
            请通过以下链接访问我们的合作信托公司 – Olympia Trust。<a className="underline fontWeightBold" href="https://support.olympiatrust.com/hc/en-us/articles/360058310594-How-can-I-open-an-account-online-#:~:text=How%20can%20I%20open%20an%20account%20online%3F%201,and%20Submit%20and%20your%20account%20will%20be%20activated">如何在线开设账户？ – Olympia Trust Company</a>   
            </p>
        </div>
    },
]

const Body = props => {
    const isMobile = window.innerWidth < 800

    return (
        <div className={`Body fadeIn ${isMobile ? 'paddingTopS' : 'paddingTopXL'}`}>
             <H2 className="fontSizeLL centerText marginOnMobile">我们解答了您最常问的问题。</H2>
{/*              <div className="covidNotice">
                <p className="fontSizeS centerText marginOnMobile">
                    During the COVID-19 pandemic, special temporary measures have been made by many institutions, including Ginkgo MIC, to do their part of social responsibility.
                </p>  
                <p className="fontSizeS centerText marginOnMobile">
                    We understand that there may be some confusion during this time. Starting March 16, investors will receive a frequent email update from Ginkgo CEO, Henry Tse, on their Ginkgo investment until COVID-19 special measures have been lifted.
                </p>
                <p className="fontSizeS centerText marginOnMobile">
                    Please see the Frequently Asked Questions below to see how Ginkgo’s temporary measures will be conducted and how it may affect you.
                </p>
            </div>
 */}            <div className='pagePadding'>
                <div className={`${!isMobile ? 'paddingRightL paddingLeftL' : ''} paddingBottomL`}>
                    {
                        questionsAndAnswers.map(({ question, answer }, i) => <QuestionAndAnswer
                            question={question}
                            answer={answer}
                            key={i}
                        />)
                    }
                </div>
            </div>
            <div className={`darkBackground lightText centerText center ${isMobile ? 'paddingL' : 'paddingXL'}`}>
                <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                    <div>
                        <H2 className='paddingBottomM paddingTopM'>
                            还有问题吗？
                        <br />与我们的专家联系，
                        <br />解答您的疑问。
                        </H2>
                        <Button to='/investor-ch/contact'>联系我们</Button>
                    </div>
                </OnScrollAnimatior>

            </div>
        </div>
    )
}
export default pure(Body)