import React from 'react'
import { pure } from 'recompose'
import { QuestionAndAnswer, OnScrollAnimatior } from '../../../../Molecules'
import { H2, Button } from '../../../../Atoms'
import { Link, withRouter } from 'react-router-dom'
import investmentGraphic from './InvestmentGraphic.png'
import './Body.css'

const questionsAndAnswers = [
    {
        question: 'Is your product open or closed?',
        answer: 
        <div>
            <p>
                Our first mortgage and bundle products are open after 3 months, while our 2nd mortgages are fully open.
            </p>
        </div>
    },
    {
        question: 'There is only one appraisal company in (subject property city/town) on your approved appraisers list. Can I use another?',
        answer: 
        <div>
            <p>
                Please try to schedule an appointment with the approved appraisal company. If there are unforeseen circumstances, please ask your Solution Advisor for more details on an alternative solution.
            </p>
        </div>
    },
    {
        question: 'How much of a brokerage fee am I allowed to blend into the lender fee?',
        answer: 
        <div>
            <p>
                You can blend up to 1.5x of the lender fee.
            </p>
        </div>
    },
    {
        question: 'How soon will I receive my commission?',
        answer: 
        <div>
            <p>
                The commission cheque will be mailed out to your brokerage/head office within 5 business days of receiving the lender fees.
            </p>
        </div>
    },
    {
        question: 'What are the fees involved with a private mortgage?',
        answer: 
        <div>
            <p>
                The fees depend on the complexity and the amount of the mortgage. Usual fees involved in a private mortgage include a lender fee, legal costs and an appraisal fee.
            </p>
        </div>
    },
    {
        question: 'Do you do mortgages on commercial properties?',
        answer: 
        <div>
            <p>
                We offer commercial mortgages on a case by case basis. We prefer mixed use residential, multi-unit residential and office units.
            </p>
        </div>
    },
    {
        question: 'How long does the approval and funding process take?',
        answer: 
        <div>
            <p>
                The approval process is usually within 24 hours if our Solution Advisors are provided with requested documents in a timely fashion. A bundle mortgage may take up to 48 hours. A commitment can be issued in 2 days or less on average. Once an appraisal, application, and supporting/signed documents are received, the case will be instructed to our lawyer within the same day. Total process time from application to funding should be around 5 business days.
            </p>
        </div>
    },
    {
        question: 'What is your minimum credit score requirement?',
        answer: 
        <div>
            <p>
                Minimum 500 credit score is required. We have provided some exceptions on a case to case basis if the borrower does not have extensive credit history.
            </p>
        </div>
    },
    {
        question: 'What does replacement deal mean?',
        answer: 
        <div>
            <p>
                A replacement deal means the subject property currently has a second mortgage, and the intention is to replace it with another second mortgage. For any replacement deals, our maximum loan to value (LTV) is 75%.
            </p>
        </div>
    },
    {
        question: 'Is the appraisal necessary for all deals?',
        answer: 
        <div>
            <p>
                Yes.
            </p>
        </div>
    },
    {
        question: 'What properties do you not lend to?',
        answer: 
        <div>
            <p>
                Gas stations, rooming houses, raw land (unless there is a collateral property), body shops, grow ops, any properties with environmental concerns.
            </p>
        </div>
    },
    {
        question: 'What is a loan proposal and where is the commitment?',
        answer: 
        <div>
            <p>
                The loan proposal is issued after your client accepts our offer. They do not have to sign the proposal but if they wish to proceed, they are required to pay the retainer fee for our solicitor. We implemented this process because our lawyers were not getting paid when the borrower would cancel the deal. The borrower can receive the retainer fee back provided the file was not instructed. Once the retainer fee is paid, we issue a commitment for signing.
            </p>
        </div>
    },
    {
        question: 'What documents are required on submission?',
        answer: 
        <div>
            <p>
                Please provide a Filogix application form along with current credit bureaus pulled by the agent. We cannot accept an Equifax report from the borrower. If you have the purchase and sale agreement, we would appreciate that as well.
            </p>
        </div>
    }
]

const Body = props => {
    const isMobile = window.innerWidth < 800

    return (
        <div className={`Body fadeIn ${isMobile ? 'paddingTopS' : 'paddingTopXL'}`}>
            <H2 className="fontSizeLL centerText marginOnMobile">General Mortgage FAQ</H2>
            <div className='pagePadding'>
                <div className={`${!isMobile ? 'paddingRightL paddingLeftL' : ''} paddingBottomL`}>
                    {
                        questionsAndAnswers.map(({ question, answer }, i) => <QuestionAndAnswer
                            question={question}
                            answer={answer}
                            key={i}
                        />)
                    }
                </div>
            </div>
            <div className={`darkBackground lightText centerText center ${isMobile ? 'paddingL' : 'paddingXL'}`}>
                <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                    <div>
                        <H2 className='paddingBottomM paddingTopM'>
                            Still have questions?
                        <br /> Connect with our specialist to get
                        <br /> your questions answered.
                        </H2>
                        <Button to='/contact'>Contact Us</Button>
                    </div>
                </OnScrollAnimatior>
            </div>
        </div>
    )
}
export default pure(Body)