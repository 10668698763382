import React from "react";

import Elane from "./images/elane.png";
import ElaneSmall from "./images/elaneSmall.png";

import Yvonne from "./images/yvonne.png";
import YvonneSmall from "./images/yvonneSmall.png";

import Henry from "./images/henryBig.png";
import HenrySmall from "./images/henrySmall.png";

// import Ronald from "./images/ronaldBig.png";
// import RonaldSmall from "./images/ronaldSmall.png";

import Peter from "./images/peterBig.png";
import PeterSmall from "./images/peterSmall.png";
export const teams = [
    {
        name: <span>Elaine Mak</span>,
        title: <span>投资者关系总监</span>,
        text: (
            <span>
                Elaine Mak，Ginkgo盈信投资者关系总监，拥有超过15年的房贷和个人金融行业经验。她擅长与业务合作伙伴建立并维持稳固的关系，确保沟通顺畅，实现共同成功。凭借深厚的财务分析和风险控制专业知识，Elaine为客户提供战略性建议，帮助他们最大化投资回报并实现长期财务目标。她对客户成功的承诺以及应对复杂金融环境的能力，使她成为公司及其投资者不可或缺的宝贵资产。
                <br />
                <br /> 如果您对您的投资有疑问，Elaine 将能够提供帮助。
            </span>
        ),
        bigImage: Elane,
        smallImage: ElaneSmall
    },
    {
        name: <span>YVONNE LEUNG</span>,
        title: <span>投资者关系</span>,
        text: (
            <span>
                Yvonne自Ginkgo MIC创立初期便加入公司。凭借超过20年的客户服务和活动策划经验，她一直致力于策划有吸引力的教育研讨会，确保我们兑现承诺：保持透明并维护投资者的利益。
                <br />
                <br /> 如果您是Ginkgo的新客户，有任何疑问，或希望获得个人介绍，Yvonne将很乐意为您提供更多信息。欢迎通过以下方式与她联系：
                <br />
                <br /> (647) 952-8117 | yvonne@ginkgomic.com
            </span>
        ),
        bigImage: Yvonne,
        smallImage: YvonneSmall
    },
    {
        name: <span>HENRY TSE</span>,
        title: (
            <span>
                总裁、董事会主席
                <br /> CPA CA MBA CFP AMP CHS CLU
            </span>
        ),
        text: (
            <span>
                Henry，Ginkgo盈信总裁，致力于推动公司取得卓越成功。他拥有McMaster University工商管理硕士（MBA）学位，并取得特许会计师（CA）资格，在Ernst &amp; Young晋升为审计经理后获得此资质。Henry创立了自己的公共会计事务所，并担任过三家TSX创业交易所（TSX Venture Exchange）公司的首席财务官（CFO），进一步深化了他的专业知识。凭借超过20年的房地产投资和私人抵押贷款领域经验，他以深厚的行业知识，通过对行业和经济趋势的战略性分析，确保投资的安全性与稳健性。在他的领导下，Ginkgo盈信注重协作与创新，保持在市场中的竞争力。
            </span>
        ),
        bigImage: Henry,
        smallImage: HenrySmall
    },
    /*
    {
        name: <span>RONALD LEE</span>,
        title: (
            <span>
                Board Member
                <br /> CPA CA MBA CFP AMP CHS CLU
            </span>
        ),
        text: (
            <span>
                Ronald has been working in the financial services industry since 1989, and is the owner of iBrokerPower
                Capital Inc, a Mortgage Centre Canada franchise. With his vast experience and many awards.
                <br />
                <br /> He is a trusted advisor for the public, appearing often on local television and radio to inform
                on financial matters. As a specialist in residential, commercial mortgages and business loans, has
                helped many Canadians obtain financing for both their homes and business.
            </span>
        ),
        bigImage: Ronald,
        smallImage: RonaldSmall
    },
    */
    {
        name: <span>PETER WONG</span>,
        title: (
            <span>
                首席运营官、董事会成员
                <br /> P.Eng., MBA, MSC
            </span>
        ),
        text: (
            <span>
                Peter拥有University of Toronto工程学应用科学学士学位，以及Indiana University Kelly School of Business 全球供应链管理硕士（Master of Science in Global Supply Chain Management）和工商管理硕士（Master of Business Administration）学位。凭借超过25年的实际管理经验，他目前担任安大略省政府监管机构——技术标准与安全局（Technical Standards and Safety Authority, TSSA）的运营副总裁（Vice President of Operations）。他的职责包括为TSSA的七个项目领域提供战略指导并确保运营效率。此外，Peter还负责监督组织的许可、注册及行业合规性。 在2013年6月加入TSSA之前，他曾在联合技术公司（United Technologies）旗下的两家子公司Chubb和Verex担任高级管理职位。
            </span>
        ),
        bigImage: Peter,
        smallImage: PeterSmall
    }
];
