import React, { PureComponent } from 'react'
import { OnScrollAnimatior } from '../../../../Molecules'
import { pure } from 'recompose'
import './SpecialPromotion.css'
import condo from './condo.png'
import nodocs from './nodocs.png'
import g6month from './6g6month.webp'
import feesplit from './50feesplit.png'
import axios from 'axios'
import { serURL } from '../../../../API/url'


// const plans = ['6G - 6 month term', 'No Docs Mortgage', 'Condo Rescue']
const plans = ['6G - 6 month term', 'No Docs Mortgage']
const SP6GRateRow = ({
    rateData: {
        Title: rowName,
        Column1: ltv1,
        Column2: ltv2,
 //       Column3: ltv3,
        Column4: ltv4,
    }
}) => (
    <tr>
        <td class="c1"><b>{rowName}</b></td>
        <td class="c2">{ltv1}</td>
        <td class="c3">{ltv2}</td>
{/*          <td class="c4">{ltv3}</td>
 */}         <td class="c4">{ltv4}</td>
    </tr>
);        

class SpecialPromotion extends PureComponent {

    state = {
        activeTab: 0,
        rate6G: []
    }

    formatUrl(url){
        if (url.startsWith('http')){
            return url;
        }else{
            return "http://"+url;
        }
    }
    renderColumn(index, value){

        var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        var regex = new RegExp(expression);

        if (value.match(regex)) {
            return <a href={this.formatUrl(value)}><i className="fas fa-link"></i></a>;
        } else {
            return value;
        }
    }

    readSP6G = async() => {
  
        const get6Grate = await axios.get(serURL+"/sprates"
           ).then((response) => response
           )
           .catch((err) => console.log(err))
         this.setState({rate6G: get6Grate.data})
     }
    
    componentDidMount() {
            this.readSP6G()
        }
    
    render() {
        const isMobile = window.innerWidth < 800
        return (
        <div className={`SpecialPromotion pagePadding paddingBottomXL ${!isMobile ? 'paddingTopXL' : 'paddingTopL'}`}>
            <div className='tableWrap paddingTopL'>
                <div className='underLine primaryBackground'>
                </div>
                    <div className='thead'>
                        {plans.map((plan, i) => (
                            <div
                                className={`${i === this.state.activeTab ? 'primaryBackground lightText' : 'lightishBackground'} paddingS fontSizeM roundedTopBig center bold`}
                                onClick={() => this.setState({ activeTab: i })}
                                key={i}
                            >
                                {plan}
                            </div>
                        ))}
                    </div>
{/*                     <div className={`condoRescue ${this.state.activeTab === 2 ? '' : 'hidden'}`}>
                        <div className='condoRescue-image'>
                            <img src={condo} alt='' />
                        </div>
                        <div className='condoRescue-text'>
                            <h2 className="orangeText">Builder won't provide an extension?</h2>
                            <h2 className="greenText">"CONDO RESCUE" SOLUTION</h2>
     
                            <h3>Product Highlight:</h3>
                            <ul>
                                <li key="1"><b>High LTV:</b> up to 75% of Purchase Price with Beacon Score {'>'} 600</li>
                                <li key="2"><b>Affordable rate:</b> 1.5% per month</li>
                                <li key="3"><b>Flexible term:</b> Fully open</li>
                            </ul>
                            <h3 className="greenText">What benefits do you get?</h3>
                            <ul>
                                <li key="1"><b className="orangeText">NO</b> lender fee</li>
                                <li key="2"><b className="orangeText">NO</b> appraisal</li>
                                <li key="3"><b className="orangeText">NO</b> income documentation</li>
                                <li key="4">Could be funded in <b className="orangeText">2 days</b></li>
                                <li key="5">25,000 Power Agent points</li>
                            </ul>
                            <h3>Conditions and requirements:</h3>
                            <ul>
                                <li key="1">Greater Toronto Area only</li>
                                <li key="2">Pre-sale condo (minimum 3 years ago)</li>
                                <li key="3">Maximum loan $600k</li>
                                <li key="4">Must be closed within <b className="greenText">5 days</b></li>
                                <li key="5">$1,000 lawyer retainer fee</li>
                            </ul>
                        </div>
                    </div>
 */}                    <div className={`condoRescue ${this.state.activeTab === 1 ? '' : 'hidden'}`}>
                        <div className='condoRescue-image'>
                            <img src={nodocs} alt='' />
                        </div>
                        <div className='condoRescue-text'>
                            <h2 className="orangeText">Get a mortgage with NO income documents!</h2>
                            <h3>Product Highlight:</h3>
                            <ul>
                                <li key="1"><b>LTV:</b> up to 65% with Beacon Score {'>'} 550 </li>
                                <li key="2"><b>LTV:</b> between 65% to 75% require Beacon Score {'>'} 600 </li>
                                <li key="3"><b>Position:</b> First Mortgage and Second Mortgage</li>
                                
                            </ul>
                            <h3>Maximum loan amounts:</h3>
                            <ul>
                                <li key="1">First Mortgage: $600,000</li>
                                <li key="2">Second Mortgage: $250,000</li>
                            </ul>
                            <h3>Conditions and requirements:</h3>
                            <ul>
                                <li key="1">All purchases, refinances over maximum amount, and replacements require bank statements.</li>
                            </ul>
                        </div>
                    </div>
                    <div className={`condoRescue ${this.state.activeTab === 0 ? '' : 'hidden'}`}>
                        <div className='condoRescue-image'>
                            <img src={g6month} alt='' />
                        </div>
                        <div className='condoRescue-text'>
                            <h2 className="greenText">Ginkgo's 6 month term program</h2>
                            <h2 className="greenText">Better than 5G, it's 6G</h2>
                            <h2 className="orangeText">6 months open, interest payment only</h2>
                        <div className='tableWrap'> 
                            <table>
                                {this.state.rate6G.map((rateData) => <SP6GRateRow rateData={rateData}/>)}
{/*                                 <tr>
                                    <td class="c1"><b>LTV</b></td>
                                    <td class="c2">65%</td>
                                    <td class="c3">75%</td>
                                    <td class="c4">77.5%</td>
                                    <td class="c5">70%</td>
                                </tr>
                                <tr>
                                    <td class="c1"><b>TYPE</b></td>
                                    <td class="c2">1st</td>
                                    <td class="c3">1st</td>
                                    <td class="c4">1st</td>
                                    <td class="c5">2nd</td>
                                </tr>
                                <tr>
                                    <td class="c1"><b>STARTING RATES</b></td>
                                    <td class="c2">8.75%</td>
                                    <td class="c3">9.25%</td>
                                    <td class="c4">9.50%</td>
                                    <td class="c5">11.25%</td>
                                </tr>
                                <tr>
                                    <td class="c1"><b>FEE</b></td>
                                    <td class="c2">1.75%</td>
                                    <td class="c3">1.75%</td>
                                    <td class="c4">1.75%</td>
                                    <td class="c5">2%</td>
                                </tr>
 */}                            </table> 
                        </div>
                            <h3>Conditions and requirements:</h3>
                            <ul>
                                <li key="1">77.5% LTV for Power Agents status and above only -- 8.25% interest rate and 1.75% lender fee</li>
                                <li key="2">+25 BPS for all refinancing (including new refinancing & replacements)</li>
                                <li key="3">+25 BPS on beacon scores below 600</li>
                                <li key="4">+25 BPS for rental properties</li>
                                <li key="5">No rate buy down, cash back rebate and Brokerage Partner Pricing does not apply</li>                  
                            </ul>
                    </div>
                    </div>
{/*                     <div className={`condoRescue ${this.state.activeTab === 3 ? '' : 'hidden'}`}>
                        <div className='condoRescue-image'>
                            <img src={feesplit} alt='' />
                        </div>
                        <div className='condoRescue-text'>
                            <h2 className="orangeText">For a limited time only, 50% of <i>our</i> lender fee is <i>yours!</i></h2>
                            <h3><i>Manitoba & British Columbia deals only</i></h3>
                            <ul>
                                <li key="1">12 month term first mortgages, second mortgages and bundles *may not apply to certain programs</li>
                                <li key="2">Submit and commit a deal by June 30th, 2021 with closing dates within 30 days</li>
                            </ul>
                        </div>
                    </div>
 */}            </div>
        </div>
        )
    }
}

export default pure(SpecialPromotion)