import React, { PureComponent } from "react";
import { Header, BodySection } from "./components";
import { ContactForm, DownloadAppSection } from "../../../../Molecules";
import axios from 'axios'
import { serURL } from '../../../../API/url'

class EventsAndSeminars extends PureComponent {
    constructor(props) {  
        super(props)
          this.state = {
              returnEvents : []
            }
      }
readSPEvents = async() => {
  
        const getEvents = await axios.get(serURL+"/events-zh"
           ).then((response) => response
           )
           .catch((err) => console.log(err))
            this.setState({returnEvents: getEvents.data.filter(event => event.Display === true).sort((a,b) => a.Order0 - b.Order0)})
     }
    
    componentDidMount() {
        this.readSPEvents();
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="EventsAndSeminars">
                <Header />
                <BodySection events={this.state.returnEvents} />
                <DownloadAppSection />
                <ContactForm />
            </div>
        );
    }
}

export default EventsAndSeminars;
