import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import "./Header.css";

const Header = props => (
    <div className="Header lightText centerText withBackground">
        <H1 className="fontSizeLL">投资产品</H1>
        <div className="paddingTopM fontWeightNormal plainText fontSizeM lineHeightM fadeInUp">
        我们的目标是成为房贷投资行业的领导者，为所有利益相关者提供全面的解决方案。
        </div>
    </div>
);

export default pure(Header);
