import React from "react";
import { pure } from "recompose";
import { H2, H1, Button } from "../../../../../../Atoms";
import { OnScrollAnimatior } from "../../../../../../Molecules";
import "./BodySection.css";

import Grid from "@material-ui/core/Grid";

import image1 from "./employee.png";
import image2 from "./ginkgo-image.png";

import TimelineCard from "./TimelineCard/TimelineCard";
const timelineCards = [
    {
        number: 1,
        title: <span>2012: 基金总规模达到1,000万加元</span>,
        text: <span></span>
    },
    {
        number: 2,
        title: <span>2015: 基金总规模达到3,000万加元</span>,
        text: <span></span>
    },
    {
        number: 3,
        title: <span>2016: 向超过500位投资者支付股息1,500万加元</span>,
        text: <span></span>
    },
    {
        number: 4,
        title: <span>2018: 基金总规模达到5,000万加元</span>,
        text: <span>获得独立研究机构“2-”（“风险回报表现优秀”）评级</span>
    },
/*     {
        number: 5,
        title: <span>2019: Awarded A+ grading by Better Business Bureau</span>,
        text: <span> </span>
    },
 */    {
        number: 5,
        title: <span>2020: 基金总规模达到6,000万加元</span>,
        text: <span> </span>
    },
    {
        number: 6,
        title: <span>2023: 基金总规模达到1亿加元</span>,
        text: <span>再次获得独立研究机构“2-”（“风险回报表现优秀”）评级</span>
    },
    {
        number: 7,
        title: <span>2023: 向超过900位投资者支付股息4,000万加元</span>,
        text: <span> </span>
    }
];

const BodySection = props => {
    const isMobile = window.innerWidth < 800;
    const TimelineCards = () =>
        timelineCards.map(({ number, title, text }, i) => (
            <Grid item key={i}>
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                >
                    <TimelineCard number={number} title={title} text={text} />
                </OnScrollAnimatior>
                {/* 
                    onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
                    // KL: 2025-01-27 - cleanup, removed console-log from OnScrollAnimation element
                */}
            </Grid>
        ));
    return (
        <div className="BodySection">
            <div className="primaryBackground">
                <Grid container direction="row" justify="flex-end">
                    <Grid item>
                        <div className="fadeInUp topGridSection">
                            <div className="topSection paddingTopXL paddingRightXXL marginOnMobile">
                                <H2 className={`fontSize40 ${!isMobile ? "paddingBottomM" : ""}`}>
                                    团结让我们更强大！
                                </H2>
                                <div className="innerContent fontWeightNormal lineHeightM fontSizeS">
                                成立于2011年，总部位于多伦多，我们在抵押贷款行业提供稳定、高回报的投资工具。通过利用加拿大两个最强大和最大的房地产市场的优势，我们为投资者提供了安全、长期增长的战略定位。
                                    <br />
                                    <br />
                                    我们不同于联合抵押贷款。作为一家抵押贷款投资公司，我们为众多借款人提供替代贷款解决方案，并分散到多个物业上，以控制和降低风险。从东部的哈利法克斯到西部的温哥华，我们的业务覆盖全国，并拥有一个多元化的投资组合，以支持我们的优先股。
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <img src={image1} alt="" />
                    </Grid>
                </Grid>
            </div>
            <div className="timelineContent paddingTopXXL">
                <Grid container direction="row">
                    <Grid item xs={6}>
                        <div className="timelineLeftSection">
                            <div className="bottomSection">
                                <H1 className="fontSizeLL">成立于2011年10月</H1>
                                <H2 className="lineHeightM fontSizeMM">自创立以来，我们不断成长。</H2>
                                <div className="fontSizeS lineHeightM fontWeightNormal">
                                根据FSCO的要求，MIC的证券需通过豁免市场经销商（EMD）购买。以下是您开始财富增长的方法：
                                </div>
                                <div className="buttonRow">
                                    <Button
                                        onClick={() => {
                                            window.open("http://broker.ginkgomic.com/investor/how-to-invest");
                                        }}
                                    >
                                        阅读更多！
                                    </Button>
                                </div>
                                <img
                                    src={image2}
                                    className="timelineLeftSectionImage paddingTopLL paddingBottom200"
                                    alt=""
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="fadeInUp ">
                            <div className="timelineLeftSection">
                                <H1 className="fontSizeLL paddingBottomLL">Ginkgo 发展历程</H1>
                            </div>
                            <div>{TimelineCards()}</div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
export default pure(BodySection);
