import React from 'react'
import { pure } from 'recompose'
import { Link } from 'react-router-dom'
import { facebook, instagram, linkedin, twitter } from './icons'

import './SocialIcons.css'


const icons = [
    {
        image: linkedin,
        url: 'https://www.linkedin.com/company/ginkgomic/'
    },
    {
        image: facebook,
        url: 'https://www.facebook.com/ginkgomic/'
    },
    {
        image: instagram,
        url: 'https://www.instagram.com/ginkgomic/'
    }
]


const SocialIcons = props => (
    <div style={{ width: (props.width || 25) * icons.length + icons.length * 20 }} className='SocialIcons row' >

        {
            icons.map(({ image, url }, i) => (
                <a href={url} target="_blank" className='rowItem' key={i} aria-label={url}>
                    <img src={image} alt='' />
                </a>
            ))
        }
    </div>
)

export default pure(SocialIcons)