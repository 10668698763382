import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import "./Header.css";

const Header = props => (
    <div className="Header lightText centerText">
        <H1 className="fontSizeLL">贷款区域</H1>
        <div className="paddingTopM fontWeightNormal plainText fontSizeM lineHeightM fadeInUp marginOnMobile">
        自2011年以来，Ginkgo盈信已在加拿大各地提供贷款。您的投资分布于全国最大的城市经济体中，风险得到良好对冲。
        </div>
    </div>
);

export default pure(Header);
