import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import "./Header.css";

const Header = props => (
    <div className="Header lightText centerText withBackground">
        <H1 className="fontSizeLL">基金组合</H1>
        <div className="paddingTopM fontWeightNormal plainText fontSizeM lineHeightM fadeInUp marginOnMobile">
        了解我们的资金分配方式以及我们的抵押贷款和投资组合的表现。
        </div>
    </div>
);

export default pure(Header);
