import React, { useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel'
import arrowLeft from './arrowLeft.svg'
import arrowRight from './arrowRight.svg'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import './Carusel.css'

// const Carusel = props => (
//     <div className={`Carusel ${props.className ? props.className : ''} ${props.darkNav ? 'darkNav' : ''}`}>
//         <OwlCarousel
//             className="owl-theme"
//             loop={props.disableLoop ? false : true}
//             items={props.items || 1}
//             autoplay={true}
//             autoplayTimeout={5000}
//             autoplayHoverPause={true}
//             nav={props.nav}
//             navText={[
//                 `<img class='arrowLeft' src="${arrowLeft}" alt="" />`,
//                 `<img class='arrowRight' src="${arrowRight}" alt="" />`
//             ]}
//         >
//             {props.children.map((child, i) => (
//                 <span className='item' key={i}>
//                     {child}
//                 </span>
//             ))}
//         </OwlCarousel>
//     </div>
// )

const Carusel = (props) => {
    useEffect(() => {
        // Wait for OwlCarousel to render dots
        setTimeout(() => {
            document.querySelectorAll(".owl-dot").forEach((dot, index) => {
                dot.setAttribute("aria-label", `Go to slide ${index + 1}`);
            });
        }, 500);
    }, []);

    return (
        <div className={`Carusel ${props.className ? props.className : ''} ${props.darkNav ? 'darkNav' : ''}`}>
            <OwlCarousel
                className="owl-theme"
                loop={props.disableLoop ? false : true}
                items={props.items || 1}
                autoplay={true}
                autoplayTimeout={5000}
                autoplayHoverPause={true}
                nav={props.nav}
                navText={[
                    `<button class="owl-prev" aria-label="Previous Slide">❮</button>`,
                    `<button class="owl-next" aria-label="Next Slide">❯</button>`,
                    `<img class='arrowLeft' src="${arrowLeft}" alt="" />`,
                    `<img class='arrowRight' src="${arrowRight}" alt="" />`
                ]}
            >
                {props.children.map((child, i) => (
                    <span className='item' key={i}>
                        {child}
                    </span>
                ))}
            </OwlCarousel>
        </div>
    );
};

export default Carusel