import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import "./BodySection.css";
import SingleCard from "./SingleCard/SingleCard";
import Grid from "@material-ui/core/Grid";

import Image1 from "./image1.png";
import Image2 from "./image2.png";
import Image3 from "./image3.png";
import Image4 from "./image4.png";
import Image5 from "./image5.png";
const images = [
    {
        images: [Image1, Image2]
    },
    {
        images: [Image3]
    },
    {
        images: [Image4, Image5]
    }
];

const BodySection = props => {
    const FAQCards = () =>
        images.map(({ images }, i) => (
            <Grid item xs={4}>
                <SingleCard images={images} />
            </Grid>
        ));

    return (
        <div className="pagePadding-Investor paddingBottomXL BodySection">
            <div className="paddingTopXL paddingBottomXL">
                <Grid container justify="center">
                    <Grid item xs={6}>
                        <H1 className="fontSizeLL">照片画廊</H1>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justify="center" className="paddingTopM">
                            <Grid item xs={3}>
                                <div className="fontSizeS centerText paddingBottomS">全部</div>
                                <div className="blueLine darkBackground"></div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="fontSizeS centerText paddingBottomS">活动</div>
                                <div className="darkBackground"></div>
                            </Grid>
                            {/*<Grid item xs={3}>*/}
                            {/*    <div className="fontSizeS centerText paddingBottomS">AGM</div>*/}
                            {/*    <div className="darkBackground"></div>*/}
                            {/*</Grid>*/}
                            <Grid item xs={3}>
                                <div className="fontSizeS centerText paddingBottomS">旅行</div>
                                <div className="darkBackground"></div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className="paddingTopXL" container justify="center">
                    {FAQCards()}
                </Grid>
            </div>
        </div>
    );
};

export default pure(BodySection);
