import React from 'react'
import { pure } from 'recompose'
import { H2, Button } from '../../Atoms'
import { OnScrollAnimatior } from '../../Molecules'
import './StayConnected.css'

const StayConnected = props => {

    const isMobile = window.innerWidth < 800
    return (
    <div className='lightText centerText StayConnected pagePadding'>
        <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
            <H2>
                {window.location.pathname.includes('/investor-ch') ? '保持联系' : 'Stay Connected'}
            </H2>
            <div className='fontSizeS paddingTopM paddingBottomM plainText'>
                {window.location.pathname.includes('/investor-ch') ? '通过 Ginkgo MIC 获取最新的经纪人新闻——我们将新促销、Power Agent 积分声明以及午餐与学习活动的邀请直接发送到您的收件箱！' : 'Stay on top with the latest broker news from Ginkgo MIC - we send new promotions, Power Agent points statements, and invitations to our Lunch & Learns straight to your inbox!'}
            </div>
            <a target="_blank" href='https://go.pardot.com/l/361851/2021-07-29/3c9dnp'><Button backgroundClassName={props.buttonBackgroundClassName || 'darkishBackground'}>{window.location.pathname.includes('/investor-ch') ? '订阅' : 'SUBSCRIBE'}</Button></a>
        </OnScrollAnimatior>
    </div>
    )
}

export default pure(StayConnected)