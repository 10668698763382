import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import { OnScrollAnimatior } from "../../../../../../Molecules";
import "./BodySection.css";

import image1 from "./image1.png";
import image2 from "./image2.png";
import image3 from "./image3.png";

import SingleCard from "./SingleCard/SingleCard";
const aboutUsCards = [
    {
        image: image1,
        title: <span>我们的理念</span>,
        text: (
            <span>
                <b>质量、透明度和信任</b> ——这是我们认为卓越产品和经得起时间考验的业务必须具备的核心价值观。
                <br />
                <br />
                我们确保通过以下标准实现我们的核心价值观：
                <br />
                <br />
                - 稳健收益
                <br />
                - 可控风险
                <br />
                - 信赖之选
            </span>
        ),
        isImageOnLeft: true
    },
    {
        image: image2,
        title: <span>我们的承诺</span>,
        text: (
            <span>
                在盈信Ginkgo，我们努力成为替代贷款的黄金标准。
                <br />
                <br />
                您将投资委托给我们，以增加您的财富或退休基金，因此我们非常重视我们的承诺。我们不会隐藏或提供虚假报告——信任是赢得的，我们拥有稳固的业务——我们将确保您定期收到有关您的投资的详细更新。
            </span>
        ),
        isImageOnLeft: false
    },
    {
        image: image3,
        title: <span>我们的慈善事业</span>,
        text: (
            <span>
                在 Ginkgo，我们心怀感恩，认识到社区在取得成功时的重要性。我们每年都会在 Second Harvest 做志愿者，以此回馈社会。Second Harvest 是一家多伦多非营利组织，致力于增加新鲜食品的可获得性，同时减少食物浪费。 <br />
                <br />
                我们的员工在 Second Harvest 仓库工作，作为一种团队建设，对新鲜农产品进行分类，这些农产品原本会因为不符合杂货店标准的美观而被填埋。<br />
                <br />
                因此，自 2016 年启动这项计划以来，我们已为提供超过 16,500 顿餐食和数百磅新鲜农产品免遭浪费做出了贡献。{" "}
            </span>
        ),
        isImageOnLeft: true
    }
];

const BodySection = props => {
    const isMobile = window.innerWidth < 800;

    const AboutUsCards = () =>
        aboutUsCards.map(({ image, title, text, isImageOnLeft }, i) => (
            <OnScrollAnimatior
                key={i}
                animation="fadeInUp"
                delay={i * 100}
                preAnimationStyles={{ opacity: 0 }}
                isMobile={isMobile}
                onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
            >
                <SingleCard image={image} title={title} text={text} isImageOnLeft={isImageOnLeft} />
            </OnScrollAnimatior>
        ));

    return (
        <div className="BodySection pagePadding-Investor">
            <div
                className={`aboutUs fadeInUp primaryBackground ${
                    !isMobile ? "paddingTopXL paddingBottomXL roundedBig" : "paddingL"
                }`}
            >
                <div className={!isMobile ? "paddingRightXL paddingLeftXL" : ""}>
                    <H1 className={`fontSizeLL centerText ${!isMobile ? "paddingBottomM" : ""}`}>关于</H1>
                    <div className="bodyText fontWeightNormal lineHeightM fontSizeM">
                        <p>
                        Ginkgo盈信房贷基金是一家知名的加拿大全国替代贷款机构，帮助借款人解决棘手的财务问题，同时通过高收益按揭贷款为投资者提供稳健的财富增长。
                        </p>
                        <p>
                        我们的目标：成为加拿大领先的房贷投资公司，以对诚信、创新以及投资者和商业伙伴的长期财务成功的承诺而闻名。​{" "}
                        </p>
                    </div>
                </div>
            </div>

            <div className="paddingTopXXL">{AboutUsCards()}</div>
        </div>
    );
};
export default pure(BodySection);
