import React from "react";
import { pure } from "recompose";
import { H1, H2, Button } from "../../../../../../Atoms";
import { OnScrollAnimatior } from "../../../../../../Molecules";
import SingleCard from "./SingleCard/SingleCard";
import "./BodySection.css";

import Grid from "@material-ui/core/Grid";

import Logo from "./logo.png";
const reviews = [
    {
        name: <span>Daniel Oh</span>,
        text: (
            <span>
                他们非常友好，也非常关心他们的投资者。在许多方面中，我印象最深的是他们在运营上的透明度。没有隐瞒，没有欺骗。从投资者的角度来看，这是一项投资中最关键的考虑因素之一。他们回答了我提出的所有问题（我问了很多，直到完全明白并对自己有信心）。他们的回答都非常清晰，而不是含糊其辞。我一定会向对抵押贷款投资感兴趣的朋友推荐这个机会。
            </span>
        )
    },
    {
        name: <span>Addison Cham</span>,
        text: (
            <span>
                与他们合作投资已有6年，一直获得稳定的回报，员工也非常热心。每个月收到关于利息回报的短信是我非常期待的事情。
            </span>
        )
    },
    {
        name: <span>Vienna Kwan</span>,
        text: (
            <span>
                这是一家非常有组织且细致的公司。他们以高度的专业精神管理和照顾您的投资。他们非常透明，为投资者提供详细的信息，并及时更新市场情况和客户投资的状态。他们的客户服务尤其出色且专业，能够跟进您的疑问，并在您需要时随时提供帮助。作为一名非常满意的客户，我总是向我的亲朋好友推荐和介绍这只基金。
            </span>
        )
    },
    {
        name: <span>Michelle Ng</span>,
        text: (
            <span>
                从开始到结束，银杏团队始终高效、专业，并让我感到安心。Yvonne Leung在我第一次发邮件的当天就联系了我。随后，我与首席执行官Henry Tse进行了会面，他向我详细解释了公司业务及相关风险。这里的团队经验丰富，知识渊博，且非常擅长与客户沟通。<br/><br/>我刚开始学习了解豁免市场，在选择任何投资公司时，我希望能找到一家值得信赖的公司，并由专业人士为我解释和引导。在整个过程中，Yvonne出色的客户关系能力、Henry作为首席执行官的专业指导、Elaine细致地准备表格，以及接待处的礼貌接待，都让我感受到非常积极的体验。
            </span>
        )
    }
];

const BodySection = props => {
    const isMobile = window.innerWidth < 800;

    const ReviewCards = () =>
        reviews.map(({ name, title, text }, i) => (
            // onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
            <Grid item xs={6}>
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                >
                    <SingleCard name={name} title={title} text={text} />
                </OnScrollAnimatior>
            </Grid>
        ));

    return (
        <div className="BodySection ">
            <div className="fistSection pagePadding-Investor withBackground">
                <div className="paddingTopXXL">
                    <div className="fadeInUp primaryBackground paddingL roundedBig">
                        <div className="centerText">
                            <H1 className="fontSizeLL paddingBottomS">Ginkgo</H1>
                            <H2 className="fontSizeM paddingBottomS">了解分析师对Ginkgo的评价！</H2>
                            <div className="fontWeightNormal lineHeightM fontSizeS paddingLeftM paddingRightM paddingBottomM">
                                <p>
                                我们绝不在质量和勤勉上妥协，这也是为什么Ginkgo盈信在私人抵押贷款行业中拥有可靠且资深领导者的良好声誉的原因。因此，在2017年房地产繁荣与萧条周期中，许多MIC（抵押投资公司）倒闭，而我们不仅能够持续服务，还在同一个财年内实现了公司5%的增长。
                                </p>
                            </div>
                            <div className="paddingBottomM">
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = "/investor-ch/analyst-rating";
                                    }}
                                >阅读分析师对 Ginkgo 的评价！</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="secondSection pagePadding-Investor paddingTopXXL">
                <Grid container direction="row">
                    <Grid item xs={4}>
                        <div className="fadeInUp">
                            <div>
                                <H1 className="fontSizeLL paddingBottomS">为什么投资者钟爱 Ginkgo</H1>
                                <div className="innerContent fontWeightNormal lineHeightM fontSizeS">
                                我们深知沟通与责任对投资者至关重要。因此，我们通过清晰的月度报告、分红公告和年度股东大会，确保投资者安心无忧。
                                </div>
                            </div>
                            <div className="paddingTopLL">
                                <Button
                                    onClick={() => {
                                        window.open("/investor-ch/gallery");
                                    }}
                                >查看我们的照片！</Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid className="paddingLeftM" item xs={8}>
                        <Grid className={`${isMobile ? "paddingTopL" : ""}`} container>
                            {ReviewCards()}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
{/*             <div className="fadeInUp secondSection pagePadding-Investor paddingTopXL centerText">
                <H2 className="fontSize28 paddingBottomS">
                    Ginkgo is an Accredited Business with the Better Business Bureau
                </H2>
                <div className="paddingTopML fontWeightNormal lineHeightM fontSizeS">
                    Accredited since March 3, 2018
                </div>
                <div className="textContent paddingTopM fontWeight300 lineHeightM fontSize26">
                    We want you to have peace of mind and feel confident about your investment. That is why we are
                    registered with the Better Business Bureau, and adhere to the outlined Standards of Trust for
                    ethical business practices.
                    <br />
                    <br />
                    <div class="underline greenText">
                        <a href="https://www.bbb.org/ca/on/north-york/profile/mortgage-lenders/ginkgo-mortgage-investment-corporation-0107-1373779">
                            See our BBB Profile
                        </a>
                    </div>
                </div>
                <img className="LogoImage" src={Logo} alt="" />
            </div>
 */}        </div>
    );
};
export default pure(BodySection);
