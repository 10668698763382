import React from "react";
import { H2 } from "../../../../../../../Atoms";
import { pure } from "recompose";
import "./SingleCard.css";
import Image from "../image1.png";
const defaultValues = {
    image: Image,
    name: "Paul Smith",
    title: "Bank-level Service",
    text:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
};

const SingleCard = props => (
    <div className="SingleCard paddingBottomML">
        <div className="reviewSection  lightishBackground roundedBig">
            <div className="innerReviewSection paddingL centerText">
                <img className="topImage" src={props.image || defaultValues.image} alt="" />
                <H2 className="fontSizeMM paddingTopM">{props.title || defaultValues.title}</H2>
                <div className="innerContentSection fontSizeS fontWeightNormal paddingTopL">
                    {props.text || defaultValues.text}
                </div>
                <div className="buttonRow slideTwoButtonRow fontWeightNormal">
                    <a href={props.link}>了解更多</a>
                </div>
            </div>
        </div>
    </div>
);

export default pure(SingleCard);
