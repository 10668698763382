import React, { useState }  from "react";
import { pure } from "recompose";
import { H1, Button } from "../../../../../../Atoms";
import { OnScrollAnimatior } from "../../../../../../Molecules";
import "./RatingGraphSection.css";
import ReviewCard from "../../../../../../Molecules/Investor/ReviewCard/ReviewCard";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Select, MenuItem} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(0),
      minWidth: 120,
    },
    selectEmpty: {
      marginRight: theme.spacing(1),
      lineHeight: '27px',
    },
  }));
  
const reviews = [
    {
        name: <span>Daniel Oh</span>,
        title: <span>Google Reviews</span>,
        text: (
            <span>
                They are very friendly and care about their investors. From many, one thing I am very impressed is their
                transparency on their operation. No hiding, no lying.
            </span>
        )
    },
    {
        name: <span>Addison Cham</span>,
        title: <span>Google Reviews</span>,
        text: (
            <span>
                Been investing with them for 6 years, have been getting stable returns and the staffs are very helpful.
                The text I get for the interest return is something I look forward to every month.
            </span>
        )
    }
];

const RatingGraphSection = props => {
    const [year, setYear] = useState(2025);
    const handleChange = (event) => {
      setYear(event.target.value);
    };
    const classes = useStyles();
    const isMobile = window.innerWidth < 800;

    const ReviewCards = () =>
        reviews.map(({ name, title, text }, i) => (
            <Grid item xs={6}>
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                    onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
                >
                    <ReviewCard name={name} title={title} text={text} />
                </OnScrollAnimatior>
            </Grid>
        ));
    return (
        <div className="pagePadding-Investor paddingTopXL RatingGraphSection centerText marginOnMobile">
            <H1 className="fontSizeLL middleHeader">Ginkgo MIC is "Consistently Generating Above-Average Yields"</H1>
            <div className="paddingTopM fontWeightBold plainText fontSizeXS lineHeightM fadeInUp">
                Analysis by independent research group, Fundamental Research Corporation
            </div>
            <div className="paddingTopL paddingBottomL fontSizeM fontWeightNormal lineHeightM captionSection">
            Once again, we are pleased to announce that Fundamental Research Corp. (FRC) has given Ginkgo MIC an overall 2-rating for a fourth consevutive period, indicating a very good Return to Risk Ratio. FRC is one of the largest independent research houses in the world, with a 17-year track record of covering 550+ companies. FRC aims to assist investors in making informed investment decisions and was ranked the #1 research firm globally amongst 130+ investment firms in 2021. <br/><br/>
            FRC believes that the Bank of Canada will initiate rate cuts in H1-2024, driven by rising unemployment, financialinstability, and mortgage costs, declining consumer confidence, and cooling inflation. They anticipate transation volumes picking up this year, driven by lower interest rates. Anticipating declines, FRC find Ginkgo MIC increasingly appealing, projecting yields of 9.2% in FY2024. <br/><br/>
            Ginkgo MIC remains steadfast in providing consistent dividends to its investors, having distributed more than $45 million in dividends since its establishment. The recent FRC report further validates our commitment to delivering secure and stable investments, reinforcing the trust and value we offer to our stakeholders.<br/><br/>
            Congratulations to our Gingko’s team for a job well done!
            </div>
            <div className="paddingTopM paddingBottomXL">
                <FormControl >
                    <Select
                          value = {year}
                          onChange={handleChange}
                          className={classes.selectEmpty}
                        >
                          <MenuItem value={2019}>2019</MenuItem>
                          <MenuItem value={2021}>2021</MenuItem>
                          <MenuItem value={2022}>2022</MenuItem>
                          <MenuItem value={2024}>2024</MenuItem>
                          <MenuItem value={2025}>2025</MenuItem>
                    </Select>
                </FormControl>
                    <Button
                        onClick={() => {
                            if (year == 2025){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EThxVKOJEalGgrUUk7UGNnMB18gXSMn5eBVPPFIsLTVssA?e=zBl3va"
                                );
                            };
                            if (year == 2024){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EUaXelKKCdlFuKhYE5tgmXoBA_AtgqDExC0Jed2MZQ7qGg?e=2eENHL"
                                );
                            };
                            if (year == 2022){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EdM0BQlWBadHhxgsk2TOYFcBaPV4D7PuJZ7Y6YurM-TiyQ"
                                );
                            };
                            if (year == 2021){
                                window.open(
                                    "https://bit.ly/2RUPaQH"
                                );
                            };
                            if (year == 2019){
                                window.open(
                                    "https://bit.ly/2Fy4A7r"
                                );
                            }}}
                            >SEE REPORT
                    </Button>
             </div>

            <div className="paddingBottomXL">
                <Grid container direction="row">
                    <Grid item xs={4}>
                        <H1 className="leftText fontSizeLL paddingBottomL">Why investors love Ginkgo</H1>
                    </Grid>
                    <Grid className="paddingLeftM" item xs={8}>
                        <Grid container>{ReviewCards()}</Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default pure(RatingGraphSection);
