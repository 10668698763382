import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import { OnScrollAnimatior } from "../../../../../../Molecules";
import "./ReportSection.css";
import SingleCard from "./SingleCard/SingleCard";
import Grid from "@material-ui/core/Grid";

import Image1 from "./image1.png";
import Image2 from "./image2.png";
import Image3 from "./image3.png";
import Image4 from "./image4.png";

const reviews = [
    {
        image: Image1,
        title: <span>分析师评级</span>,
        text: (
            <span>
                第三方独立研究团队撰写的Ginkgo盈信商业模式及盈利能力深度分析。
            </span>
        ),
        link: "/investor-ch/analyst-rating"
    },
    {
        image: Image2,
        title: <span>贷款组合</span>,
        text: (
            <span>
                您的投资通过多样化的按揭资产池分散风险。查看您的资金如何分布于加拿大的各类房地产项目中。
            </span>
        ),
        link: "/investor-ch/loan-portfolio"
    },
    {
        image: Image3,
        title: <span>基金组合</span>,
        text: (
            <span>
                了解我们的投资表现——每月绩效报告，让您清楚了解资金的用途。
            </span>
        ),
        link: "/investor-ch/fund-portfolio"
    },
    {
        image: Image4,
        title: <span>回报历史</span>,
        text: <span>自2011年以来，我们持续为投资者提供高且稳健的回报，无任何中断。</span>,
        link: "/investor-ch/historical-return"
    }
];
const ReportSection = props => {
    const isMobile = window.innerWidth < 800;

    const ReviewCards = () =>
        reviews.map(({ image, title, text, link }, i) => (
            <Grid item className="paddingRightML ZeroRightPaddingOnMobile">
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                >
                    <SingleCard image={image} title={title} text={text} link={link} />
                </OnScrollAnimatior>
            </Grid>
        ));
        // onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
        // KL: 2025-01-27 - removed console.log from OnScrollAnimation above

    return (
        <div className="pagePadding-Investor paddingTopXL paddingBottomXL GraphSection">
            <Grid className="" justify="center" container direction="row">
                <Grid item xs={5}>
                    <div className="paddingTopS fontWeightNormal fontSizeS lineHeightM fadeInUp">
                    凭借对质量的承诺，Ginkgo盈信已成为房贷投资行业的顶尖选择之一。
                    </div>
                </Grid>
            </Grid>

            <Grid className="paddingTopXXL" container space={3} justify="center">
                {ReviewCards()}
            </Grid>
        </div>
    );
};

export default pure(ReportSection);
