import React from "react";
import { pure } from "recompose";
import "./RatingGraphSection.css";

const LoanPortfolioTableRow = ({
    loan: {
        Account: acc,
        Street: street,
        City: city,
        Province: province,
        Amount: amount,
        Rate: rate,
        LTV: ltv,
    }
}) => (
    <tr>
        <td>{acc}</td>
        <td>{street.replace(/[0-9]|-/g,'')}</td>
        <td>{city}</td>
        <td>{province}</td>
        <td align="right">{amount}</td>
        <td>{rate}</td>
        <td>{ltv}</td>
    </tr>
);



const LoanPortfolioTable = ( { loans } ) => {
//    console.log(loans)
    return (
    <div class="container">
        <div ng-app="myApp" ng-controller="customersCtrl" class="LoadPortfolioTableDiv">
            <table striped class="LoadPortfolioTable  table table-striped table-condensed table-responsive">
                <thead>
                    <tr>
                        <td>账户</td>
                        <td>街道</td>
                        <td>城市</td>
                        <td>省份</td>
                        <td align="center">贷款</td>
                        <td>利率</td>
                        <td>LTV</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        loans.map((loan) => <LoanPortfolioTableRow  loan={loan}/>)
}
                </tbody>
            </table>
        </div>
    </div>
    )
};

export default pure(LoanPortfolioTable);
