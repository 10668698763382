import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import "./Header.css";

const Header = props => (
    <div className="Header lightText centerText withBackground">
        <H1 className="fontSizeLL">为什么选择我们</H1>
        <div className="paddingBottomL fontWeightNormal plainText fontSizeM lineHeightM fadeInUp marginOnMobile">
            <p>质量。透明度。信任。</p>
            这些核心价值观是我们公司文化的核心，也是我们每个决策的基础。我们知道，信任是赢得的，而不是给予的，因此我们努力证明自己是一个值得信赖的名字。
        </div>
    </div>
);

export default pure(Header);
