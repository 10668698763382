import React from 'react'
import { pure } from 'recompose'
import { H1 } from '../../../../Atoms'
import './Header.css'

const Header = props => (
    <div className='Contact Header withBackground lightText centerText center'>
        <div>
            <H1 className='fadeInUp'>{window.location.pathname.includes('/investor-ch')? '联系我们' : 'Contact Us'}</H1>
            <div className='fontSizeM fadeInUp'>
            {window.location.pathname.includes('/investor-ch')? '我们期待您的信息' : 'We\'d love to hear from you.'}
            </div>
        </div>
    </div>
)

export default pure(Header)