import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../Atoms";
import "./DownloadAppSection.css";
import appIcons from "./app-icons.png";

const DownloadAppSection = props => {

    if (window.location.pathname.includes('/investor')) {
        return null;
    }
    return (
    <div className="DownloadAppSection withBackground centerText lightText">
        <H1 className="fontSizeLL paddingTopLL">Download the future</H1>
        <div className="paddingTopS fontWeightNormal plainText fontSizeS lineHeightM fadeInUp">
            Stay on top with the latest broker news from Ginkgo MIC – we send new promotions, Power Agent points
            statements, and invitations to our Lunch & Learns straight to your inbox!{" "}
        </div>
        <img className="appImages paddingTopS" src={appIcons} alt="" />
    </div>
    );
};

export default pure(DownloadAppSection);
