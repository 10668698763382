import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import "./Header.css";

const Header = props => (
    <div className="Header lightText withBackground centerText">
        <H1 className="fontSizeLL">贷款组合</H1>
        <div className="marginOnMobile paddingTopM fontWeightNormal plainText fontSizeM lineHeightM fadeInUp">
        我们的目标成是为加拿大领先的房贷投资公司，以诚信、创新，以及投资者和业务合作伙伴的长期财务成功而闻名。​
        </div>
    </div>
);

export default pure(Header);
