import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import "./Header.css";

const Header = props => (
    <div className="Header lightText withBackground centerText">
        <H1 className="fontSizeLL">常见问题</H1>
        <div className="marginOnMobile paddingM fontWeightNormal plainText fontSizeM lineHeightM fadeInUp">
            我们的目标是通过为所有利益相关者提供全面的解决方案，成为私人抵押贷款行业的杰出领导者。
        </div>
    </div>
);

export default pure(Header);
