import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../Atoms";
import "./ResourcesSection.css";

const ResourcesSection = props => (
    <div className="ResourcesSection withBackground">
        <H1 className="fontSizeLL paddingTopXXL">{window.location.pathname.includes('/investor-ch') ? '资源' : 'Resources'}</H1>
        <div className="paddingTopS fontWeightNormal plainText fontSizeS lineHeightM fadeInUp">
            {window.location.pathname.includes('/investor-ch') ? '想了解更多关于 Ginkgo 的信息吗？查看我们的业绩投资组合。' : 'Want to learn more about Ginkgo? See our Performance Portfolio'}
        </div>
    </div>
);

export default pure(ResourcesSection);
