import React from 'react'
import { Link } from 'react-router-dom'
import { pure } from 'recompose'
import './Button.css'

const Button = props => {
    const isMobile = window.innerWidth < 800

    const buttonElem = (
        <button
            aria-label={props.ariaLabel || 'Learn More'}
            onClick={props.onClick || function () { }}
            className={`Button roundedSmall ${isMobile ? 'fontSizeS' : 'fontSizeXS'} bold paddingRightL paddingLeftL ${props.backgroundClassName || 'ctaBackground'} ${props.className}`}
        >
            {props.children || 'Learn More'}
        </button>
    )

    return (
        props.to ?
            <Link to={props.to}>
                {buttonElem}
            </Link>
            : buttonElem
    )
}
export default pure(Button)