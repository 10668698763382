import React, { Component } from "react";
import { pure } from "recompose";
import { H1, H2 } from "../../../../../../Atoms";
import "./BodySection.css";
import Grid from "@material-ui/core/Grid";

import { teams } from "./BodySectionConstants";

import Instagram from "./images/instagram.png";
import Facebook from "./images/facebook.png";
import Twitter from "./images/twitter.png";
class BodySection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: 0
        };
    }
    selectTeamMember(index) {
        this.setState({ selected: index });
    }

    TeamMembers() {
        return teams.map(({ smallImage }, i) => (
            <Grid item xs={2}>
                <button
                    className="imageButton"
                    onClick={() => {
                        this.selectTeamMember(i);
                    }}
                >
                    <img src={smallImage} alt="" />
                </button>
            </Grid>
        ));
    }

    render() {
        return (
            <div className="pagePadding-Investor paddingTopXL paddingBottomXL BodySection">
                <div className="paddingLeftXL paddingRightXL NoPaddingOnMobile">
                    <div className="centerText">
                        <H1 className="fontSizeLL">Our Team</H1>
                        <div className="paddingTopM fontWeightNormal plainText fontSizeM lineHeightM fadeInUp FirstSectionInnerContnet">
                            Our dedicated staff have many years of expertise across the vast financial sector. They live
                            our values daily, and are committed to giving you a great investment experience.
                        </div>
                    </div>
                    <Grid className="paddingTopXXL" container direction="row" justify="center">
                        <Grid item>
                            <img className="roundedBig mainImage" src={teams[this.state.selected].bigImage} alt="" />
                        </Grid>
                        <Grid item>
                            <div className="contentDiv paddingLeftL NoPaddingOnMobile">
                                <H2 className="fontSize28 paddingTopL">{teams[this.state.selected].name}</H2>
                                <H2 className="fontSizeMM paddingTopS titleSection">
                                    {teams[this.state.selected].title}
                                </H2>
                                <div className="paddingTopS fontSizeXS fontWeightNormal lineHeightM textSection">
                                    {teams[this.state.selected].text}
                                </div>
{/*                                 <div className="">
                                    <img className="paddingRightML" src={Twitter} alt="" />
                                    <img className="paddingRightML" src={Facebook} alt="" />
                                    <img className="paddingRightML" src={Instagram} alt="" />
                                </div>
 */}                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Grid className="paddingTopXL" container justify="center" direction="row">
                        {this.TeamMembers()}
                    </Grid>
                </div>
            </div>
        );
    }
}
export default pure(BodySection);
