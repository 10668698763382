import React from 'react'
import { pure } from 'recompose'
import { QuestionAndAnswer, OnScrollAnimatior } from '../../../../../../Molecules'
import { H2, Button } from '../../../../../../Atoms'
import { Link, withRouter } from 'react-router-dom'
import './Body.css'

const questionsAndAnswers = [
/*      {
        question: 'Can I redeem my investment during the COVID-19 lock-down period?',
        answer: 
        <div>
            <p>
                At this time Ginkgo will not be serving any redemptions until June 30, 2020. We will be doing our part in social consciousness during this time by providing a 3-month mortgage payment deferral program to the borrowers, and this measure will allow us to provide sufficient operations during and beyond this time of temporary uncertainty.Rest assured that we have sufficient provisions to do so without impacting your investment, and your monthly interest income distribution will be continued during these times.
            </p>
        </div>
    },
     {
        question: 'Are there any restrictions on redemption?',
        answer: 
        <div>
            <p>
                Investors will not be able to redeem their investment from March 31 to June 30th,2020, or until further notice. After this period, investors will be able to redeem a maximum 5% of total investment not more than $25,000 each month.
            </p>
        </div>
    },
    {
        question: 'Will investors still receive dividends?',
        answer: 
        <div>
            <p>
                Yes, investors will continue to receive their monthly dividends during this period.    
            </p>
        </div>
    },
    {
        question: 'Will the dividend rate will be reduced?',
        answer: 
        <div>
            <p>
                We do not foresee a rate reduction in the immediate future, but it will be consideredif necessary. As the COVID-19 pandemic is an unprecedented phenomenon, it is difficult to predict future measures. We are constantly monitoring the overall economic and mortgage landscape, and will make appropriate adjustments to ensure the longevity of your investment.    
            </p>
        </div>
    },
      {
        question: 'If I would like to have a safer investment, are there any options?',
        answer: 
        <div>
            <p>
                Ginkgo MIC investments are secured by real estate, which are one of the most enduring assets throughout history. Investors currently holding Preferred Shares cantransfer to our Bond products, which will receive a fixed rate of 5% (1 year term - Bond A) or 6% return (2 year term - Bond B). The principle balance is secured by Ginkgo MIC’s assets through a General Securities Agreement (GSA), and bond holders will be paid first in the case of liquidation, meaning bonds have a lower risk than preferred shares.&nbsp;
                <Link to="/investor/investment-products" className={"link"}>
                        See our investment products
                </Link>
            </p>
        </div>
    },
*/    {
        question: 'Who can I contact about my investment?',
        answer: 
        <div>
            <p>
                You can contact our Investor Support Line (647) 660-2293 <a className="underline fontWeightBold" href="mailto:investor@ginkgomic.com">investor@ginkgomic.com</a> or Yvonne Leung (416) 990-5567 <a className="underline fontWeightBold" href="mailto:yvonne@ginkgomic.com">yvonne@ginkgomic.com</a> or Jeffery Chiu (647) 255-9828 <a className="underline fontWeightBold" href="mailto:yvonne@ginkgomic.com">jeffery.chiu@ginkgomic.com</a>
            </p>
        </div>
    },
    {
        question: 'What is the minimum amount I can invest?',
        answer: 
        <div>
            <p>
                The minimum amount is $10,000, and will require the investor to have an Eligible Investor Certificate. Investors who choose to invest over $100,000 will require an Accredited Investor Certificate. Both certificates can be completed with your Exempt Market Dealer (EMD). An appointment with an EMD can also be made through us by calling us at +1 (855) 901-5133.
            </p>
        </div>
    },
    {
        question: 'When will dividends be paid?',
        answer: 
        <div>
            <p>
                Dividends are paid on the 15th of every month. You will receive an SMS notifying you of the amount paid to your Ginkgo account. An e-mail will also be sent to you regarding your investment’s overall performance.
            </p>
        </div>
    },
    {
        question: 'How will I know my investment is doing well?',
        answer: 
        <div>
            <p>
               Just like a bank, we will issue a monthly report to you via e-mail to inform you of your investment's performance and standing.
            </p>
            <p>
                You will also be invited to our Annual General Meeting, so you can have a better understanding of the company's performance. It is a great opportunity to also meet the staff who manage your investments and be informed of projected economic outlooks. Therefore, it is very important to provide up-to-date contact information in which we can inform you about your investment. Your personal information is held privately, only used for the regular performance of the corporation. It will not be sold to third parties.
            </p>
        </div>
    },
/*     {
        question: 'I heard about Ponzi schemes. How do I know Ginkgo MIC is not one of those?',
        answer: 
        <div>
            <p>
                This is a good question. A Ponzi scheme does not have a real business. It is a fraudulent investment operation that pays returns to its investors from the investor's own money or the money paid by subsequent investors, rather than from profit earned by the company's operation. The Ponzi scheme usually entices new investors by offering higher returns than other investments, in the form of short-term returns that are either abnormally high or unusually consistent. Perpetuation of the high returns requires an ever-increasing flow of money from new investors to keep the scheme going.     
            </p>
        </div>
    },
 */    {
        question: 'What is the difference between a first and second mortgage?',
        answer: 
        <div>
            <p>
                The main difference between a first and second mortgage is debt seniority.   
            </p>
            <p>
                In the event of a default, both first and second mortgagees have the right to foreclose on the property and sell it on the market for repayment. However, the first mortgagee is at the first position to get the proceeds; and the second mortgagee will be entitled to the remaining amount. Since second mortgages are behind the first mortgage, they are considered more risky and therefore demand a higher return.
            </p>
        </div>
    },
    {
        question: 'How does Ginkgo invest your funds? ',
        answer: 
        <div>
            <p>
            Ginkgo invests your funds into a diversified pool of mortgages and the yield to our investors is generated by the borrowers’ interest payments
            </p>
        </div>
    },
    {
        question: 'What is the expected rate of return on my investment?',
        answer: 
        <div>
            <p>
            The current expected rate of return is 8-9%   
            </p>
        </div>
    },
    {
        question: 'What is the process for redeeming my investment?',
        answer: 
        <div>
            <p>
            Shareholders may on a monthly basis request redemption of any or all outstanding shares by providing 30 days advance notice to the us.  Our shares are subject to early termination fees payable to us in the first two years from the date of subscription.  Redemptions requested after two years from the date of subscription are completed without any early redemption fees.  Shares cannot be redeemed in the first 6 months. 
            </p>
        </div>
    },
    {
        question: 'How do I set up a registered account?',
        answer: 
        <div>
            <p>
            Please follow the link below to our partnered trust company – Olympia Trust. <a className="underline fontWeightBold" href="https://support.olympiatrust.com/hc/en-us/articles/360058310594-How-can-I-open-an-account-online-#:~:text=How%20can%20I%20open%20an%20account%20online%3F%201,and%20Submit%20and%20your%20account%20will%20be%20activated">How can I open an account online? – Olympia Trust Company</a>   
            </p>
        </div>
    },
]

const Body = props => {
    const isMobile = window.innerWidth < 800

    return (
        <div className={`Body fadeIn ${isMobile ? 'paddingTopS' : 'paddingTopXL'}`}>
             <H2 className="fontSizeLL centerText marginOnMobile">We solved your most commonly asked questions</H2>
{/*              <div className="covidNotice">
                <p className="fontSizeS centerText marginOnMobile">
                    During the COVID-19 pandemic, special temporary measures have been made by many institutions, including Ginkgo MIC, to do their part of social responsibility.
                </p>  
                <p className="fontSizeS centerText marginOnMobile">
                    We understand that there may be some confusion during this time. Starting March 16, investors will receive a frequent email update from Ginkgo CEO, Henry Tse, on their Ginkgo investment until COVID-19 special measures have been lifted.
                </p>
                <p className="fontSizeS centerText marginOnMobile">
                    Please see the Frequently Asked Questions below to see how Ginkgo’s temporary measures will be conducted and how it may affect you.
                </p>
            </div>
 */}            <div className='pagePadding'>
                <div className={`${!isMobile ? 'paddingRightL paddingLeftL' : ''} paddingBottomL`}>
                    {
                        questionsAndAnswers.map(({ question, answer }, i) => <QuestionAndAnswer
                            question={question}
                            answer={answer}
                            key={i}
                        />)
                    }
                </div>
            </div>
            <div className={`darkBackground lightText centerText center ${isMobile ? 'paddingL' : 'paddingXL'}`}>
                <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                    <div>
                        <H2 className='paddingBottomM paddingTopM'>
                            Still have questions?
                        <br /> Connect with our specialist to get
                        <br /> your questions answered.
                        </H2>
                        <Button to='/contact'>Contact Us</Button> 
                    </div>
                </OnScrollAnimatior>

            </div>
        </div>
    )
}
export default pure(Body)