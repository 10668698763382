import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import { OnScrollAnimatior } from "../../../../../../Molecules";
import "./ReportSection.css";
import SingleCard from "./SingleCard/SingleCard";
import Grid from "@material-ui/core/Grid";

import Image1 from "./image1.png";
import Image2 from "./image2.png";
import Image3 from "./image3.png";
import Image4 from "./image4.png";

const reviews = [
    {
        image: Image1,
        title: <span>Loan-to-Values</span>,
        text: (
            <span>
                我们最多借出物业当前价值的80%，且物业的最低价值必须为$250,000或以上。
            </span>
        ),
        link: "/investor-ch/analyst-rating"
    },
    {
        image: Image2,
        title: <span>人口统计</span>,
        text: (
            <span>
                我们接受位于人口超过50,000的市政区的物业。主要地区包括大多伦多地区、温哥华、温尼伯、卡尔加里和埃德蒙顿。
            </span>
        ),
        link: "/investor-ch/analyst-rating"
    },
    {
        image: Image3,
        title: <span>文件资料</span>,
        text: (
            <span>
                我们要求提供标准的抵押贷款文件、信用报告、评估报告以及6个月的银行对账单。还可能需要提供公司章程、雇佣信函和2张工资单。
            </span>
        ),
        link: "/investor-ch/fund-portfolio"
    },
    {
        image: Image4,
        title: <span>了解我们的借款人</span>,
        text: (
            <span>
                人们借款于Ginkgo有很多原因。了解我们如何审核最终用户，以及他们如何从Ginkgo的产品中受益。
            </span>
        ),
        link: "/investor-ch/analyst-rating"
    }
];
const ReportSection = props => {
    const isMobile = window.innerWidth < 800;

    const ReviewCards = () =>
        reviews.map(({ image, title, text, link }, i) => (
            <Grid item className="paddingRightL GridSection" key={i}>
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                >
                    <SingleCard image={image} title={title} text={text} link={link} />
                </OnScrollAnimatior>
            </Grid>
        ));
        // onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}

    return (
        <div className="pagePadding-Investor paddingTopXL paddingBottomXL GraphSection">
            <Grid className="" justify="center" container direction="row">
                <Grid item xs={6}>
                    <H1 className="fontSizeLL">我们如何承保抵押贷款</H1>
                </Grid>
                <Grid item xs={5}>
                    <div className="paddingTopS fontWeightNormal fontSizeS lineHeightM fadeInUp">
                        我们专注的解决方案顾问团队与加拿大各地的经纪人合作，寻找优质的物业和借款人。每笔抵押贷款必须符合以下所有标准，才能成为我们贷款组合的一部分。
                    </div>
                </Grid>
            </Grid>

            <Grid className="paddingTopXXL" container justify="center">
                {ReviewCards()}
            </Grid>
        </div>
    );
};

export default pure(ReportSection);
