import React from "react";
import { pure } from "recompose";
import { H2 } from "../../../../../../Atoms";
import "./BodySection.css";
import Grid from "@material-ui/core/Grid";
import image1 from "./image1.png";
import image2 from "./image2.png";
import image3 from "./image3.jpg";
import logo2 from "./logo2.png";
import logo3 from "./logo3.png";
import henry from "./henry.jpg";
import image4 from "./agm21.jpg";
import cruise from "./2021Cruise.png";
import promotion from "./2022Promotion.png";
import AGM from "./2022AGMPressRelease.png"
import RedCross from "./2022RedCross.png"
import special from "./Special.png"
import { OnScrollAnimatior } from '../../../../../../Molecules'
import { EventCard } from '../../../../../NewsAndEvents/components/MainSection/components'
import '../../../../../NewsAndEvents/components/MainSection/MainSection.css'
const BodySection = ({events}) =>  {
    const isMobile = window.innerWidth < 800;
    const spacingElem = <div className={isMobile ? 'paddingM' : 'paddingL'}></div>
    return (
         <div className="pagePadding-Investor paddingTopSL paddingBottomXL BodySection">
      {
                    events.map((event, i) => (
                        <OnScrollAnimatior
                            animation='fadeInUp'
                            key={i}
                            isMobile = {isMobile}
                            preAnimationStyles={{ opacity: 0 }}
                        >
                            <div >
                                <EventCard event={event} />
                                {spacingElem}
                            </div>
                        </OnScrollAnimatior>
                    ))
                }
      </div>
    );
};

export default pure(BodySection);
