import React from "react";
import { pure } from "recompose";
import { H1, Button } from "../../../Atoms";
import "./ReadyToInvestSection.css";

const ReadyToInvestSection = props => (
    <div className="ReadyToInvestSection centerText withBackground">
        <H1 className="fontSizeLL paddingTopXXL">{window.location.pathname.includes('/investor-ch') ? '准备好投资了吗？' : 'Ready to invest?'}</H1>
        <div className="paddingTopL paddingBottomM">
        <a target="_blank" href='https://outlook.office365.com/owa/calendar/GinkgoMortgageInvestmentCorp@ibusinesspower.com/bookings/s/fLlEmknzm0ymp82gIKIbFA2'><Button>{window.location.pathname.includes('/investor-ch') ? '注册' : 'SIGN UP'}</Button></a>
        </div>
        <div className="paddingTopS fontWeightNormal plainText fontSizeXS lineHeightM fadeInUp">
            {window.location.pathname.includes('/investor-ch') ? '我们的产品可通过豁免市场经销商轻松购买。我们将安排一次初步会议，以确保您适合进行 Ginkgo 投资。' : 'Our products are easily purchased through an Exempt Market Dealer. We will set up an introductory meeting to ensure you are suitable for a Ginkgo investment.'}
        </div>
    </div>
);

export default pure(ReadyToInvestSection);
